import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import 'react-select/dist/react-select.css';

export default class ContactForm extends React.Component {
  static propTypes = {
    handleNewContact: PropTypes.func,
  };

  constructor(props, _railsContext) {
    super(props);
    this.state = {
      user_id: "",
      contact_alert: "None",
      source: "",
      type: "",
      client_rep: "",
      client_rep_options: [
        { value: "Axel Sosa", label: "Axel Sosa" },
        { value: "Brett Nowacki", label: "Brett Nowacki" },
        { value: "Bryan Baker", label: "Bryan Baker" },
        { value: "Dave Major", label: "Dave Major" },
        { value: "Dennis Harmon", label: "Dennis Harmon" },
        { value: "Emily Martin", label: "Emily Martin" },
        { value: "Jim Crume", label: "Jim Crume" },
        { value: "John Martin", label: "John Martin" },
        { value: "Pete Priestner", label: "Pete Priestner" },
        { value: "Philip Gershkovich", label: "Philip Gershkovich" },
        { value: "Sandy Little", label: "Sandy Little" },
        { value: "Tom Warren", label: "Tom Warren" },
        { value: "Tyler Seabase", label: "Tyler Seabase" },
        { value: "Zachary Bearley", label: "Zachary Bearley" },
        { value: "Zachary Radel", label: "Zachary Radel" },
      ],
      f_name: "",
      l_name: "",
      email: "",
      emailMessage: "",
      accounting_email: "",
      phone: "",
      ext: "",
      mobile: "",
      position: "",
      add_billing_info: "No",
      billing_contact: "",
      billing_address: "",
      billing_city: "",
      billing_state: "",
      billing_zip: "",
      billing_email: "",
      billing_phone: "",
      billing_notes: "",
      editing: false,
    };
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/contacts/${this.props.officeId}`,
        dataType: "JSON",
      }).done((data) => {
        this.setState({
          user_id: data.contact.user_id,
          contact_alert: data.contact.contact_alert,
          source: data.contact.source,
          type: data.contact.type,
          office_id: data.contact.office_id,
          client_rep: data.contact.client_rep,
          f_name: data.contact.f_name,
          l_name: data.contact.l_name,
          email: data.contact.email,
          accounting_email: data.contact.accounting_email,
          phone: data.contact.phone,
          ext: data.contact.ext,
          mobile: data.contact.mobile,
          position: data.contact.position,
          add_billing_info: data.contact.add_billing_info,
          billing_contact: data.contact.billing_contact,
          billing_address: data.contact.billing_address,
          billing_city: data.contact.billing_city,
          billing_state: data.contact.billing_state,
          billing_zip: data.contact.billing_zip,
          billing_email: data.contact.billing_email,
          billing_phone: data.contact.billing_phone,
          billing_notes: data.contact.billing_notes,
          editing: this.props.match.path === "/contacts/:id/edit",
        });
      });
    }
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ? this.updateContact() : this.addContact();
  };

  updateContact() {
    const contact = {
      user_id: this.state.user_id,
      office_id: this.props.officeId,
      contact_alert: this.state.contact_alert,
      source: this.state.source,
      type: this.state.type,
      client_rep: this.state.client_rep,
      f_name: this.state.f_name,
      l_name: this.state.l_name,
      email: this.state.email,
      accounting_email: this.state.accounting_email,
      phone: this.state.phone,
      ext: this.state.ext,
      mobile: this.state.mobile,
      position: this.state.position,
      add_billing_info: this.state.add_billing_info,
      billing_contact: this.state.billing_contact,
      billing_address: this.state.billing_address,
      billing_city: this.state.billing_city,
      billing_state: this.state.billing_state,
      billing_zip: this.state.billing_zip,
      billing_email: this.state.billing_email,
      billing_phone: this.state.billing_phone,
      billing_notes: this.state.billing_notes,
    };
    $.ajax({
      type: "PATCH",
      url: `/contacts/${this.props.match.params.id}`,
      data: { contact: contact },
    })
      .done((_data) => {
        console.log("Contact updated!");
        this.props.history.goBack("/contacts");
      })
      .catch((error) => {
        console.log("Contact error: ", error.response.data.message);
      })
      .fail((_response) => {
        console.log("did not work", _response);
        alert("Please review the form for errors", _response);
      });
  }

  addContact() {
    const contact = {
      user_id: this.state.user_id,
      office_id: this.props.officeId,
      contact_alert: this.state.contact_alert,
      source: this.state.source,
      type: this.state.type,
      client_rep: this.state.client_rep,
      f_name: this.state.f_name,
      l_name: this.state.l_name,
      email: this.state.email,
      accounting_email: this.state.accounting_email,
      phone: this.state.phone,
      ext: this.state.ext,
      mobile: this.state.mobile,
      position: this.state.position,
      add_billing_info: this.state.add_billing_info,
      billing_contact: this.state.billing_contact,
      billing_address: this.state.billing_address,
      billing_city: this.state.billing_city,
      billing_state: this.state.billing_state,
      billing_zip: this.state.billing_zip,
      billing_email: this.state.billing_email,
      billing_phone: this.state.billing_phone,
      billing_notes: this.state.billing_notes,
    };
    $.post("/contacts", { contact: contact })
      .done((data) => {
        this.props.handleNewContact(data);
        console.log("Contact Successfully Created!");
      })
      .catch((error) => {
        console.log("Contact error: ", error.responseText);
        alert(
          "Please review the form for: " +
            error.responseText.replace(/\W/g, " ")
        );
      })
      .fail((_response) => {
        console.log(_response);
        alert("Please review the form for errors");
      });
  }

  deleteContact = () => {
    if (confirm("Are you sure you want to delete this contact?")) {
      $.ajax({
        type: "DELETE",
        url: `/contacts/${this.props.match.params.id}`,
      })
        .done((_data) => {
          this.props.history.push("/contacts");
        })
        .fail((_response) => {
          console.log("Contact deletion failed!");
        });
    }
  };

  handleChange(e) {
    // Update the state for the field
    this.setState({ [e.target.name]: e.target.value });

    // Check if the field being changed is the email field
    if (e.target.name === "email") {
      // Call the email validation method
      this.checkEmail(e.target.value);
    }
  }

  checkEmail(email) {
    if (email) {
      $.ajax({
        url: `/contacts/check_email`,
        type: "GET",
        data: { email: email },
        dataType: "json",
        success: (data) => {
          if (data.url) {
            this.setState({
              emailMessage: (
                <a href={data.url} className={data.class || ""}>
                  {data.message}
                </a>
              ),
              emailMessageClass: data.class || "", // Store the class
            });
          } else {
            this.setState({
              emailMessage: data.message,
              emailMessageClass: data.class || "", // Store the class
            });
          }
        },
        error: (error) => {
          console.error("Error checking email:", error);
        },
      });
    }
  }

  handleSelectChange(key) {
    return function ({ value }) {
      this.setState({ [key]: value });
    }.bind(this);
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <h4 className="mb-4 text-4xl">
            {this.state.editing ? "Update Contact" : "Create Contact"}
          </h4>
          <small>
            (* represents a <strong>required field</strong>)
          </small>
          <br />
          <br />
          <h6 id="header">Classification Legend</h6>
          <div className="row">
            <small>
              Targets - Someone that could use our services but there has been
              no communication.
            </small>
          </div>
          <div className="row">
            <small>
              Suspects - Someone you have had communication with, they can use
              our services but have not provided a proposal.
            </small>
          </div>
          <div className="row">
            <small>
              Prospects - Someone you have provided a proposal but have not
              gotten any work from.
            </small>
          </div>
          <div className="row">
            <small>
              Client - Someone you have done work for in the last 2 years, if
              not they revert back to prospect
            </small>
          </div>
          <div className="row">
            <small>
              Network - Anyone that will never need your services but is
              connected to the industry and you need to stay in touch with
            </small>
          </div>
          <div className="row">
            <small>Networking contact - connectors</small>
          </div>
          <form onSubmit={this.handleFormSubmit}>
            <div className="row">
              <div className="col-md-4">
                <label title="Contact classification">Classification *</label>
                <Select
                  name="type"
                  required
                  value={this.state.type}
                  onChange={this.handleSelectChange("type")}
                  options={[
                    { value: "Target", label: "Target" },
                    { value: "Suspect", label: "Suspect" },
                    { value: "Prospect", label: "Prospect" },
                    { value: "Client", label: "Client" },
                    { value: "Network", label: "Network" },
                  ]}
                />
              </div>
              <div className="col-md-4">
                <label title="Enter the source of the contact">Source *</label>
                <input
                  type="text"
                  name="source"
                  placeholder="Contact Source"
                  value={this.state.source}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-4">
                <label title="Enter the client rep's name">Client Rep *</label>
                <Select
                  name="client_rep"
                  required
                  value={this.state.client_rep}
                  onChange={this.handleSelectChange("client_rep")}
                  options={this.state.client_rep_options}
                />
              </div>
              <div className="col-md-4">
                <label title="Enter the contact's first name">
                  First Name *
                </label>
                <input
                  type="text"
                  name="f_name"
                  placeholder="First Name"
                  required
                  value={this.state.f_name}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-4">
                <label title="Enter the contact's last name">Last Name *</label>
                <input
                  type="text"
                  name="l_name"
                  placeholder="Last Name"
                  required
                  value={this.state.l_name}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-4">
                <label title="Enter the contact's email">Email *</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  required
                  value={this.state.email}
                  onChange={this.handleChange}
                  className="form-control"
                />
                <div className={this.state.emailMessageClass}>
                  {this.state.emailMessage}
                </div>
              </div>
              <div className="col-md-4">
                <label title="Enter the contact's email used for accounting">
                  Accounting Email
                </label>
                <input
                  type="email"
                  name="accounting_email"
                  placeholder="Accounting Email"
                  value={this.state.accounting_email}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-4">
                <label title="Enter the contact's position (if applicable)">
                  Position
                </label>
                <input
                  type="text"
                  name="position"
                  placeholder="Position"
                  value={this.state.position}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-6">
                <label title="Enter the contact's office phone number">
                  Office Phone *
                </label>
                <input
                  type="text"
                  name="phone"
                  placeholder="123-456-7890"
                  required
                  value={this.state.phone}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-3">
                <label title="Enter the contact's office phone number extension">
                  Ext
                </label>
                <input
                  type="text"
                  name="ext"
                  placeholder="Extension"
                  value={this.state.ext}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-6">
                <label title="Enter the contact's mobile number (if applicable)">
                  Mobile
                </label>
                <input
                  type="text"
                  name="mobile"
                  placeholder="123-456-7890"
                  value={this.state.mobile}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md">
                <label title="Enter contact alerts (if applicable)">
                  Contact Alert
                </label>
                <textarea
                  type="text"
                  name="contact_alert"
                  placeholder="Contact Alert"
                  value={this.state.contact_alert}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-4">
                <label title="Click to expand/hide billing information form">
                  Add Billing Information (if different than Office information)
                  *
                </label>
                <Select
                  name="add_billing_info"
                  value={this.state.add_billing_info}
                  onChange={this.handleSelectChange("add_billing_info")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
            </div>
            <p />
            <div className="row">
              {this.state.add_billing_info == "Yes" && (
                <div className="col-md">
                  <label title="If billing contact is not this contact, who is?">
                    Billing Contact *
                  </label>
                  <input
                    type="text"
                    name="billing_contact"
                    placeholder="Billing Contact"
                    value={this.state.billing_contact}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              )}
              {this.state.add_billing_info == "Yes" && (
                <div className="col-md">
                  <label title="Enter the billing contact's email">
                    Billing Email *
                  </label>
                  <input
                    type="email"
                    name="billing_email"
                    placeholder="Billing Email"
                    value={this.state.billing_email}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              )}
              {this.state.add_billing_info == "Yes" && (
                <div className="col-md">
                  <label title="Enter the billing contact's office phone number">
                    Billing Phone *
                  </label>
                  <input
                    type="text"
                    name="billing_phone"
                    placeholder="Billing Phone #"
                    value={this.state.billing_phone}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              )}
            </div>
            <p />
            <div className="row">
              {this.state.add_billing_info == "Yes" && (
                <div className="col-md">
                  <label title="Enter the billing contact's office address">
                    Billing Address *
                  </label>
                  <input
                    type="text"
                    name="billing_address"
                    placeholder="Billing Address"
                    value={this.state.billing_address}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              )}
            </div>
            <p />
            <div className="row">
              {this.state.add_billing_info == "Yes" && (
                <div className="col-md-6">
                  <label title="Enter the billing contact's office city">
                    Billing City *
                  </label>
                  <input
                    type="text"
                    name="billing_city"
                    placeholder="Billing City"
                    value={this.state.billing_city}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              )}
              {this.state.add_billing_info == "Yes" && (
                <div className="col-md-3">
                  <label title="Select the billing contact's office state">
                    Billing State *
                  </label>
                  <Select
                    name="billing_state"
                    value={this.state.billing_state}
                    onChange={this.handleSelectChange("billing_state")}
                    options={[
                      { value: "AL", label: "AL" },
                      { value: "AK", label: "AK" },
                      { value: "AZ", label: "AZ" },
                      { value: "AR", label: "AR" },
                      { value: "CA", label: "CA" },
                      { value: "CO", label: "CO" },
                      { value: "CT", label: "CT" },
                      { value: "DE", label: "DE" },
                      { value: "DC", label: "DC" },
                      { value: "FL", label: "FL" },
                      { value: "GA", label: "GA" },
                      { value: "HI", label: "HI" },
                      { value: "ID", label: "ID" },
                      { value: "IL", label: "IL" },
                      { value: "IN", label: "IN" },
                      { value: "IA", label: "IA" },
                      { value: "KS", label: "KS" },
                      { value: "KY", label: "KY" },
                      { value: "LA", label: "LA" },
                      { value: "ME", label: "ME" },
                      { value: "MD", label: "MD" },
                      { value: "MA", label: "MA" },
                      { value: "MI", label: "MI" },
                      { value: "MN", label: "MN" },
                      { value: "MS", label: "MS" },
                      { value: "MO", label: "MO" },
                      { value: "MT", label: "MT" },
                      { value: "NE", label: "NE" },
                      { value: "NV", label: "NV" },
                      { value: "NH", label: "NH" },
                      { value: "NJ", label: "NJ" },
                      { value: "NM", label: "NM" },
                      { value: "NY", label: "NY" },
                      { value: "NC", label: "NC" },
                      { value: "ND", label: "ND" },
                      { value: "OH", label: "OH" },
                      { value: "OK", label: "OK" },
                      { value: "OR", label: "OR" },
                      { value: "PA", label: "PA" },
                      { value: "RI", label: "RI" },
                      { value: "SC", label: "SC" },
                      { value: "SD", label: "SD" },
                      { value: "TN", label: "TN" },
                      { value: "TX", label: "TX" },
                      { value: "UT", label: "UT" },
                      { value: "VT", label: "VT" },
                      { value: "VA", label: "VA" },
                      { value: "WA", label: "WA" },
                      { value: "WV", label: "WV" },
                      { value: "WI", label: "WI" },
                      { value: "WY", label: "WY" },
                      { value: "MEX", label: "MEX" },
                    ]}
                  />
                </div>
              )}
              {this.state.add_billing_info == "Yes" && (
                <div className="col-md-3">
                  <label title="Enter the billing contact's office zip code">
                    Billing Zip *
                  </label>
                  <input
                    type="text"
                    name="billing_zip"
                    placeholder="Billing Zip"
                    value={this.state.billing_zip}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              )}
            </div>
            {this.state.add_billing_info == "Yes" && (
              <div>
                <br />
                <div className="row">
                  <div className="col">
                    <label>Billing Notes</label>
                    <textarea
                      type="text"
                      name="billing_notes"
                      placeholder="Billing Notes"
                      value={this.state.billing_notes || "None"}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            )}
            <p />
            <input
              title="Click to submit"
              type="submit"
              value={this.state.editing ? "Update Contact" : "Create Contact"}
              className="btn btn-secondary btn-sm"
            />
          </form>
          <br />
        </div>
      </div>
    );
  }
}
