import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dateField"];

  connect() {
    this.dateFieldTarget.addEventListener(
      "input",
      this.restrictWeekends.bind(this)
    );
  }

  restrictWeekends(event) {
    const input = event.target;
    // Set the time to midnight local time to avoid time zone issues
    const date = new Date(input.value + "T00:00:00");
    const day = date.getDay();

    // If the selected day is Saturday (6) or Sunday (0), clear the input
    if (day === 6 || day === 0) {
      alert("Weekends are not allowed. Please select a weekday.");
      input.value = ""; // Clear the invalid weekend date
    }
  }
}
