/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import "trix"
import "../controllers"
import "@rails/actiontext"
import "mapkick/bundle";
import ReactOnRails from 'react-on-rails';
// PM application
import CompanyRouter from '../bundles/Companies/components/CompanyRouter';
import OfficeRouter from '../bundles/Offices/components/OfficeRouter';
import ContactRouter from '../bundles/Contacts/components/ContactRouter';
import OpportunityRouter from '../bundles/Opportunities/components/OpportunityRouter';
import ClosedOpps from '../bundles/Opportunities/components/ClosedOpps';
import WonOpps from '../bundles/Opportunities/components/WonOpps';
import NaasOpps from "../bundles/Opportunities/components/NaasOpps";
import NaasContacts from "../bundles/Contacts/components/NaasContacts";
import NaasProposals from "../bundles/Proposals/components/NaasProposals";
import AllQuotes from '../bundles/Quotes/components/AllQuotes';
import AllSpecs from '../bundles/Specifications/components/AllSpecs';
import SpecificationRouter from '../bundles/Specifications/components/SpecificationRouter';
import QuoteRouter from '../bundles/Quotes/components/QuoteRouter';
import ProposalRouter from '../bundles/Proposals/components/ProposalRouter';
import AllProposals from '../bundles/Proposals/components/Proposals';
import FlightVendorRouter from '../bundles/FlightVendors/components/FlightVendorRouter';
import InactiveContacts from '../bundles/InactiveContacts/components/InactiveContacts';
import Estimates from '../bundles/Estimates/components/Estimates';
import SurveyEstimates from "../bundles/SurveyEstimates/components/SurveyEstimates";
import Contracts from '../bundles/Contracts/components/Contracts';
// PT application
import Projects from '../bundles/Projects/components/Projects';
import SurveyRouter from '../bundles/Surveys/components/SurveyRouter';
import SurveyMapping from '../bundles/SurveyMapping/components/Surveys';
import FlightRouter from '../bundles/Flights/components/FlightRouter';
import DroneRouter from '../bundles/Drones/components/DroneRouter';
import ScanRouter from '../bundles/Scans/components/ScanRouter';
import AeroRouter from '../bundles/Aerotriangulations/components/AeroRouter';
import LidarRouter from '../bundles/Lidars/components/LidarRouter';
import CompRouter from '../bundles/Compilations/components/CompRouter';
import CompSubRouter from '../bundles/CompSubs/components/CompSubRouter';
import RectRouter from '../bundles/Rectifications/components/RectRouter';
import EditRouter from '../bundles/Edits/components/EditRouter';
import QCRouter from '../bundles/QualityControls/components/QCRouter';
import PlotRouter from '../bundles/Plots/components/PlotRouter';
import DeliveryRouter from '../bundles/Deliveries/components/DeliveryRouter';
import InvoiceRouter from '../bundles/Invoices/components/InvoiceRouter';
import Opportunities from '../bundles/Opportunities/components/Opportunities';
import Archives from '../bundles/Archives/components/Archives';
import NotReceived from '../bundles/NotReceived/components/NotReceived';
import ImageArchives from '../bundles/ImageArchives/components/ImageArchives';
import NotStarted from '../bundles/NotStarted/components/NotStarted';
import OnHold from '../bundles/OnHold/components/OnHold';
import AtSub from '../bundles/AtSub/components/AtSub';
import DsmWaitOnAt from '../bundles/DsmWaitOnAt/components/DsmWaitOnAt';
import CompToSub from '../bundles/CompToSub/components/CompToSub';
import BimRouter from '../bundles/Bims/components/BimRouter';
import Invoiced from '../bundles/Invoiced/components/Invoiced';
import AllProjects from '../bundles/AllProjects/components/Projects';
import ProjectsNoInvoiceNum from '../bundles/ProjectsNoInvoiceNum/components/ProjectsNoInvoiceNum';
import ProjectsNotPaid from '../bundles/ProjectsNotPaid/components/ProjectsNotPaid';
import ProjectEstimates from '../bundles/ProjectEstimates/components/ProjectEstimates';
import ProjectsQuickGlance from '../bundles/ProjectsQuickGlance/components/ProjectsQuickGlance';
import InvoiceFlightFirst from '../bundles/InvoiceFlightFirst/components/InvoiceFlightFirst';
import InvoiceSurveyFirst from '../bundles/InvoiceSurveyFirst/components/InvoiceSurveyFirst';
import ATSearch from '../bundles/Aerotriangulations/components/ATSearch';
import ScanSearch from '../bundles/Scans/components/ScanSearch';
import ProjectsNoPdf from '../bundles/Projects/components/ProjectsNoPdf';
import FieldSurveys from '../bundles/FieldSurveys/components/FieldSurveys';
import AllBimProjects from '../bundles/Bims/components/AllBimProjects';
import ProjectsByPm from '../bundles/Projects/components/ProjectsByPm';
import AllProjectsByPm from '../bundles/Projects/components/AllProjectsByPm';
import Cancelled from '../bundles/CancelledProjects/components/Cancelled';
import Timesheets from '../bundles/Timesheets/components/Timesheets';
import DsmRouter from '../bundles/Dsm/components/DsmRouter';
import MetashapeRouter from '../bundles/Metashapes/components/MetashapeRouter';
import SurfaceRouter from '../bundles/Surfaces/components/SurfaceRouter';
import PlanimetricRouter from '../bundles/Planimetrics/components/PlanimetricRouter';
import ProjectReports from '../bundles/Reports/components/ProjectReports';
import ProductionReports from '../bundles/Reports/components/ProductionReports';
import UavProjects from '../bundles/UavProjects/components/Projects';
import InProcessingRouter from '../bundles/InProcessing/components/InProcessingRouter';
// CRM components
import CrmContacts from '../bundles/CrmContacts/components/Contacts';
import CrmOpportunities from '../bundles/CrmOpportunities/components/Opportunities';
import CrmCompanies from '../bundles/CrmCompanies/components/Companies';
import CrmLeads from '../bundles/CrmLeads/components/Leads';
import MarketingProjects from '../bundles/Marketing/components/Projects';
import CrmProposals from '../bundles/CrmProposals/components/Proposals';
import ProposalView from '../bundles/Proposals/components/ProposalView';
import CrmProjects from '../bundles/CrmProjects/components/Projects';
import PhotoGeodeticInvoices from '../bundles/PhotoGeodeticInvoices/components/PhotoGeodeticInvoices';
import PostQcDashboard from '../bundles/PostQcs/components/PostQcDashboard';
import Campaigns from '../bundles/Campaigns/components/Campaigns';
import Conferences from '../bundles/Conferences/components/Conferences';
import CampaignEmails from '../bundles/CampaignEmails/components/CampaignEmails';
import LearningSessions from '../bundles/LearningSessions/components/LearningSessions';
import CampaignEvents from '../bundles/CampaignEvents/components/CampaignEvents';
import SocialMedia from '../bundles/SocialMedia/components/SocialMedia';
import FlyAndHold from '../bundles/FlyAndHold/components/Projects';
import SalesLeads from '../bundles/SalesLeads/components/SalesLeads';
import GflProjects from '../bundles/GflProjects/components/GflProjects';
import ConstructionQcs from '../bundles/ConstructionQcs/components/ConstructionQcs';
import InvoiceTmDashboard from '../bundles/InvoiceTm/components/InvoiceTmDashboard';
import InvoicePercentageDashboard from '../bundles/InvoicePercentage/components/InvoicePercentageDashboard';
import Translations from '../bundles/Translations/components/Projects';
import CrmTopClients from '../bundles/CrmTopClients/components/Contacts';
import SurveyEstimate from "../bundles/SurveyEstimates/components/SurveyEstimates";

// import "../application.css";
// This is how react_on_rails can see the components in the browser.
ReactOnRails.register({
  CompanyRouter,
  OfficeRouter,
  ContactRouter,
  OpportunityRouter,
  SpecificationRouter,
  QuoteRouter,
  ProposalRouter,
  Projects,
  SurveyRouter,
  FlightRouter,
  DroneRouter,
  ScanRouter,
  AeroRouter,
  LidarRouter,
  CompRouter,
  CompSubRouter,
  RectRouter,
  EditRouter,
  QCRouter,
  PlotRouter,
  DeliveryRouter,
  InvoiceRouter,
  Opportunities,
  Archives,
  NotReceived,
  ImageArchives,
  NotStarted,
  OnHold,
  AtSub,
  CompToSub,
  BimRouter,
  Invoiced,
  AllProjects,
  ProjectsNoInvoiceNum,
  ProjectsNotPaid,
  ProjectEstimates,
  ProjectsQuickGlance,
  InvoiceFlightFirst,
  InvoiceSurveyFirst,
  AllBimProjects,
  ProjectsByPm,
  AllProjectsByPm,
  Cancelled,
  Timesheets,
  DsmRouter,
  ProjectReports,
  ProductionReports,
  ClosedOpps,
  AllQuotes,
  AllSpecs,
  WonOpps,
  NaasOpps,
  NaasContacts,
  NaasProposals,
  CrmContacts,
  DsmWaitOnAt,
  FlightVendorRouter,
  ATSearch,
  ScanSearch,
  InactiveContacts,
  ProjectsNoPdf,
  CrmOpportunities,
  CrmCompanies,
  CrmLeads,
  FieldSurveys,
  MarketingProjects,
  AllProposals,
  CrmProposals,
  ProposalView,
  CrmProjects,
  PhotoGeodeticInvoices,
  MetashapeRouter,
  SurfaceRouter,
  PlanimetricRouter,
  PostQcDashboard,
  SurveyMapping,
  Campaigns,
  Conferences,
  CampaignEmails,
  LearningSessions,
  CampaignEvents,
  SocialMedia,
  FlyAndHold,
  SalesLeads,
  GflProjects,
  ConstructionQcs,
  UavProjects,
  InProcessingRouter,
  InvoiceTmDashboard,
  InvoicePercentageDashboard,
  Translations,
  CrmTopClients,
  Estimates,
  SurveyEstimates,
  Contracts,
});
