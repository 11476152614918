import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import 'react-select/dist/react-select.css';

export default class QuoteForm extends React.Component {
  static propTypes = {
    handleNewQuote: PropTypes.func
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      bonus_perc: 0,
      total_adjustment: 0,
      adjustment_reason: "",
      flight_sub: "",
      flight_sub_options: [
        { value: "Cooper Aerial", label: "Cooper Aerial" },
        { value: "Geomni", label: "Geomni" },
        { value: "Verisk", label: "Verisk" },
        { value: "NAAS", label: "NAAS" },
        { value: "SWAS", label: "SWAS" },
        { value: "SAM", label: "SAM" },
        { value: "CSTI", label: "CSTI" },
        { value: "Kucera", label: "Kucera" },
        { value: "Paragon", label: "Paragon" },
        { value: "Terra Flight", label: "Terra Flight" },
        { value: "Talos Aviation", label: "Talos Aviation" },
        { value: "Paragon", label: "Paragon" },
        { value: "Berry and Associates", label: "Berry and Associates" },
        { value: "Aerial Surveys Int'l", label: "Aerial Surveys Int'l" },
        { value: "G Squared", label: "G Squared" },
        { value: "SurvTech Solutions", label: "SurvTech Solutions" },
        { value: "Sitech", label: "Sitech" },
        { value: "Argos", label: "Argos" },
        { value: "Atlantic Group", label: "Atlantic Group" },
        { value: "Aero-Graphics", label: "Aero-Graphics" },
        { value: "Keystone Aerial", label: "Keystone Aerial" },
        { value: "American Aerial", label: "American Aerial" },
        { value: "Valley Air", label: "Valley Air" },
        { value: "Radman Aerial", label: "Radman Aerial" },
        { value: "Air Flight SVCS", label: "Air Flight SVCS" },
        {
          value: "Skyview Aerial Photo (South CA)",
          label: "Skyview Aerial Photo (South CA)",
        },
        { value: "Midwest Aerial Photo", label: "Midwest Aerial Photo" },
        { value: "Gammair", label: "Gammair" },
        {
          value: "Richard Crouse and Associates",
          label: "Richard Crouse and Associates",
        },
        {
          value: "I.F. Rooks & Associates, Inc.",
          label: "I.F. Rooks & Associates, Inc.",
        },
        {
          value: "Aerial Data Service, Inc.",
          label: "Aerial Data Service, Inc.",
        },
        { value: "Aerodata, Inc.", label: "Aerodata, Inc." },
        { value: "Lasermap USA", label: "Lasermap USA" },
        { value: "Aerials, Inc.", label: "Aerials, Inc." },
        {
          value: "Gulf Coast Aerial Mapping",
          label: "Gulf Coast Aerial Mapping",
        },
        { value: "Air Photographics, Inc.", label: "Air Photographics, Inc." },
        { value: "Ace Aerial", label: "Ace Aerial" },
        { value: "Georgia Aerial Surveys", label: "Georgia Aerial Surveys" },
        {
          value: "Krawietz Aerial Photo Service",
          label: "Krawietz Aerial Photo Service",
        },
        {
          value: "Continental Aerial Surveys Inc.",
          label: "Continental Aerial Surveys Inc.",
        },
        { value: "Aerial Imaging Inc.", label: "Aerial Imaging Inc." },
        {
          value: "McClintock Land Associates, Inc.",
          label: "McClintock Land Associates, Inc.",
        },
        { value: "Eagle Aerial Solutions", label: "Eagle Aerial Solutions" },
        {
          value: "Rocky Mountain Aerial Surveys",
          label: "Rocky Mountain Aerial Surveys",
        },
        { value: "Blue Skies Consulting", label: "Blue Skies Consulting" },
        { value: "GeoTerra", label: "GeoTerra" },
        { value: "Quantum Spatial", label: "Quantum Spatial" },
        { value: "Aerocon", label: "Aerocon" },
        { value: "Pickett Aerial", label: "Pickett Aerial" },
        { value: "Airborne Sensing", label: "Airborne Sensing" },
        { value: "MAP RES", label: "MAP RES" },
        { value: "Predictive Service", label: "Predictive Service" },
        { value: "Wilson Company", label: "Wilson Company" },
        { value: "KBM, Inc.", label: "KBM, Inc." },
        { value: "Air Flight Service", label: "Air Flight Service" },
        { value: "Lidar Services LLC", label: "Lidar Services LLC" },
        { value: "Eagle Mapping", label: "Eagle Mapping" },
      ],
      flight_quote: "",
      flight_markup: "",
      flight_cost: "",
      flight_notes: "",
      panels: "",
      abgps: "",
      survey_sub: "",
      survey_cost: "",
      survey_quote: "",
      survey_markup: "",
      survey_notes: "",
      contact_prints: this.props.contactPrints,
      contact_prints_cp: "50",
      diapositives: "0",
      diapositives_cp: "0",
      scan_qty: "0",
      scan_cp: "0",
      lab_discount: "0",
      lab_cost: "",
      lab_notes: "",
      analytics_hp: "",
      analytics_cp: "",
      photogrammetry_cp: "",
      photogrammetry_hp: "",
      xsec: "",
      xsec_cp: "",
      xsec_hp: "",
      cartography_cp: "",
      cartography_hp: "",
      translation: "",
      translation_cp: "",
      translation_hp: "",
      map_discount: "",
      map_cost: "",
      img_hp: "",
      img_cp: "",
      plots: "0",
      plot_x: "",
      plot_y: "",
      plot_cp: "0",
      delivery: "0",
      delivery_type: "",
      delivery_type_options: [
        { value: "FTP", label: "FTP" },
        { value: "USB", label: "USB" },
        { value: "H/D", label: "H/D" },
        { value: "DVD", label: "DVD" },
      ],
      delivery_cp: "0",
      img_discount: "0",
      img_cost: "",
      quote_cost: "",
      map_notes: "",
      img_notes: "",
      plot_mount: "",
      camera: "",
      supplemental_survey: "",
      plain_gc: "",
      description: "",
      sales_tax: "",
      unit_price: "",
      shipping: "",
      discount: "",
      editing: false,
      quote_type: this.props.quoteType || "",
      user_id: this.props.userId || "",
      ...props.quote,
    };
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if(this.props.match) {
    $.ajax({
      type: "GET",
      url: `/quotes/${this.props.match.params.id}`,
      dataType: "JSON"
    }).done((data) => {
      this.setState({
        bonus_perc: data.quote.bonus_perc,
        total_adjustment: data.quote.total_adjustment,
        adjustment_reason: data.quote.adjustment_reason,
        user_id: data.quote.user_id,
        scan_qty: data.quote.scan_qty,
        opportunity_id: data.quote.opportunity_id,
        specification_id: data.quote.specification_id,
        flight_sub: data.quote.flight_sub,
        flight_quote: data.quote.flight_quote,
        flight_markup: data.quote.flight_markup,
        flight_cost: data.quote.flight_cost,
        flight_notes: data.quote.flight_notes,
        panels: data.quote.panels,
        abgps: data.quote.abgps,
        survey_sub: data.quote.survey_sub,
        survey_cost: data.quote.survey_cost,
        survey_quote: data.quote.survey_quote,
        survey_markup: data.quote.survey_markup,
        survey_notes: data.quote.survey_notes,
        contact_prints: data.quote.contact_prints,
        contact_prints_cp: data.quote.contact_prints_cp,
        diapositives: data.quote.diapositives,
        diapositives_cp: data.quote.diapositives_cp,
        scan_cp: data.quote.scan_cp,
        lab_discount: data.quote.lab_discount,
        lab_cost: data.quote.lab_cost,
        lab_notes: data.quote.lab_notes,
        analytics_hp: data.quote.analytics_hp,
        analytics_cp: data.quote.analytics_cp,
        photogrammetry_cp: data.quote.photogrammetry_cp,
        photogrammetry_hp: data.quote.photogrammetry_hp,
        xsec: data.quote.xsec,
        xsec_cp: data.quote.xsec_cp,
        xsec_hp: data.quote.xsec_hp,
        cartography_cp: data.quote.cartography_cp,
        cartography_hp: data.quote.cartography_hp,
        translation: data.quote.translation,
        translation_cp: data.quote.translation_cp,
        translation_hp: data.quote.translation_hp,
        map_discount: data.quote.map_discount,
        map_cost: data.quote.map_cost,
        img_hp: data.quote.img_hp,
        img_cp: data.quote.img_cp,
        plots: data.quote.plots,
        plot_x: data.quote.plot_x,
        plot_y: data.quote.plot_y,
        plot_cp: data.quote.plot_cp,
        delivery: data.quote.delivery,
        delivery_type: data.quote.delivery_type,
        delivery_cp: data.quote.delivery_cp,
        img_discount: data.quote.img_discount,
        img_cost: data.quote.img_cost,
        quote_cost: data.quote.quote_cost,
        map_notes: data.quote.map_notes,
        img_notes: data.quote.img_notes,
        plot_mount: data.quote.plot_mount,
        camera: data.quote.camera,
        supplemental_survey: data.quote.supplemental_survey,
        plain_gc: data.quote.plain_gc,
        description: data.quote.description,
        sales_tax: data.quote.sales_tax,
        unit_price: data.quote.unit_price,
        shipping: data.quote.shipping,
        discount: data.quote.discount,
        quote_type: data.quote.quote_type,
        editing: this.props.match.path === "/quotes/:id/edit",
      });
    });
   }
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ?
      this.updateQuote() :
      this.addQuote();
  }

  updateQuote() {
    const quote = {
      bonus_perc: this.state.bonus_perc,
      total_adjustment: this.state.total_adjustment,
      adjustment_reason: this.state.adjustment_reason,
      user_id: this.props.userId,
      scan_qty: this.state.scan_qty,
      opportunity_id: this.props.oppId,
      specification_id: this.props.specId,
      quote_type: this.props.quoteType,
      flight_sub: this.state.flight_sub,
      flight_quote: this.state.flight_quote,
      flight_markup: this.state.flight_markup,
      flight_cost: this.state.flight_cost,
      flight_notes: this.state.flight_notes,
      panels: this.state.panels,
      abgps: this.state.abgps,
      survey_sub: this.state.survey_sub,
      survey_cost: this.state.survey_cost,
      survey_quote: this.state.survey_quote,
      survey_markup: this.state.survey_markup,
      survey_notes: this.state.survey_notes,
      contact_prints: this.props.contactPrints,
      contact_prints_cp: this.state.contact_prints_cp,
      diapositives: this.state.diapositives,
      diapositives_cp: this.state.diapositives_cp,
      scan_cp: this.state.scan_cp,
      lab_discount: this.state.lab_discount,
      lab_cost: this.state.lab_cost,
      lab_notes: this.state.lab_notes,
      analytics_hp: this.state.analytics_hp,
      analytics_cp: this.state.analytics_cp,
      photogrammetry_cp: this.state.photogrammetry_cp,
      photogrammetry_hp: this.state.photogrammetry_hp,
      xsec: this.state.xsec,
      xsec_cp: this.state.xsec_cp,
      xsec_hp: this.state.xsec_hp,
      cartography_cp: this.state.cartography_cp,
      cartography_hp: this.state.cartography_hp,
      translation: this.state.translation,
      translation_cp: this.state.translation_cp,
      translation_hp: this.state.translation_hp,
      map_discount: this.state.map_discount,
      map_cost: this.state.map_cost,
      img_hp: this.state.img_hp,
      img_cp: this.state.img_cp,
      plots: this.state.plots,
      plot_x: this.state.plot_x,
      plot_y: this.state.plot_y,
      plot_cp: this.state.plot_cp,
      delivery: this.state.delivery,
      delivery_type: this.state.delivery_type,
      delivery_cp: this.state.delivery_cp,
      img_discount: this.state.img_discount,
      img_cost: this.state.img_cost,
      quote_cost: this.state.quote_cost,
      map_notes: this.state.map_notes,
      img_notes: this.state.img_notes,
      plot_mount: this.state.plot_mount,
      camera: this.state.camera,
      supplemental_survey: this.state.supplemental_survey,
      plain_gc: this.state.plain_gc,
      description: this.state.description,
      sales_tax: this.state.sales_tax,
      unit_price: this.state.unit_price,
      shipping: this.state.shipping,
      discount: this.state.discount,
    };
    $.ajax({
      type: "PATCH",
      url: `/quotes/${this.props.match.params.id}`,
      data: { quote: quote }
    })
      .done((data) => {
        console.log('Quote updated!');
      })
      .fail((response) => {
        console.log("did not work");
        alert("Please review the form for errors");
      });
  }

  addQuote() {
    const quote = {
      bonus_perc: this.state.bonus_perc,
      total_adjustment: this.state.total_adjustment,
      adjustment_reason: this.state.adjustment_reason,
      user_id: this.props.userId,
      scan_qty: this.state.scan_qty,
      opportunity_id: this.props.oppId,
      specification_id: this.props.specId,
      quote_type: this.props.quoteType,
      flight_sub: this.state.flight_sub,
      flight_quote: this.state.flight_quote,
      flight_markup: this.state.flight_markup,
      flight_cost: this.state.flight_cost,
      flight_notes: this.state.flight_notes,
      panels: this.state.panels,
      abgps: this.state.abgps,
      survey_sub: this.state.survey_sub,
      survey_cost: this.state.survey_cost,
      survey_quote: this.state.survey_quote,
      survey_markup: this.state.survey_markup,
      survey_notes: this.state.survey_notes,
      contact_prints: this.props.contactPrints,
      contact_prints_cp: this.state.contact_prints_cp,
      diapositives: this.state.diapositives,
      diapositives_cp: this.state.diapositives_cp,
      scan_cp: this.state.scan_cp,
      lab_discount: this.state.lab_discount,
      lab_cost: this.state.lab_cost,
      lab_notes: this.state.lab_notes,
      analytics_hp: this.state.analytics_hp,
      analytics_cp: this.state.analytics_cp,
      photogrammetry_cp: this.state.photogrammetry_cp,
      photogrammetry_hp: this.state.photogrammetry_hp,
      xsec: this.state.xsec,
      xsec_cp: this.state.xsec_cp,
      xsec_hp: this.state.xsec_hp,
      cartography_cp: this.state.cartography_cp,
      cartography_hp: this.state.cartography_hp,
      translation: this.state.translation,
      translation_cp: this.state.translation_cp,
      translation_hp: this.state.translation_hp,
      map_discount: this.state.map_discount,
      map_cost: this.state.map_cost,
      img_hp: this.state.img_hp,
      img_cp: this.state.img_cp,
      plots: this.state.plots,
      plot_x: this.state.plot_x,
      plot_y: this.state.plot_y,
      plot_cp: this.state.plot_cp,
      delivery: this.state.delivery,
      delivery_type: this.state.delivery_type,
      delivery_cp: this.state.delivery_cp,
      img_discount: this.state.img_discount,
      img_cost: this.state.img_cost,
      quote_cost: this.state.quote_cost,
      map_notes: this.state.map_notes,
      img_notes: this.state.img_notes,
      plot_mount: this.state.plot_mount,
      camera: this.state.camera,
      supplemental_survey: this.state.supplemental_survey,
      plain_gc: this.state.plain_gc,
      description: this.state.description,
      sales_tax: this.state.sales_tax,
      unit_price: this.state.unit_price,
      shipping: this.state.shipping,
      discount: this.state.discount,
    };
    $.post('/quotes', { quote: quote })
      .done((data) => {
        this.props.handleNewQuote(data);
        console.log("Quote Successfully Created!");
      })
      .fail((response) => {
        console.log("did not work");
        alert("Please review the form for errors");
      });
  }

  deleteQuote = () => {
    if (confirm("Are you sure you want to delete this quote?")) {
      $.ajax({
        type: "DELETE",
        url: `/quotes/${this.props.match.params.id}`,
      })
        .done((data) => {
          this.props.history.push('/quotes');
        })
        .fail((response) => {
          console.log("Quote deletion failed!");
        });
    }
  }

  handleChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  handleSelectChange(key) {
    return function ({ value }) {
      this.setState({ [key]: value });
    }.bind(this);
  }

  render() {
    {
      /* contact prints cost */
    }
    var contactPrintsQty = parseFloat(this.state.contact_prints);
    var contactPrintsCp = parseFloat(this.state.contact_prints_cp);
    var contactPrintsPrice =
      parseFloat(contactPrintsQty) * parseFloat(contactPrintsCp);
    var contactPrintsTotal = contactPrintsPrice;

    {
      /* diapositives cost */
    }
    var diapositivesQty = parseFloat(this.state.diapositives);
    var diapositivesCp = parseFloat(this.state.diapositives_cp);
    var diapositivesPrice =
      parseFloat(diapositivesQty) * parseFloat(diapositivesCp);
    var diapositivesTotal = diapositivesPrice;

    {
      /* scan cost */
    }
    var scanNumber = parseFloat(this.state.scan_qty);
    var scanCp = parseFloat(this.state.scan_cp);
    var scanPrice = parseFloat(scanNumber) * parseFloat(scanCp);
    var scanTotal = scanPrice;

    {
      /* photo subtotal cost */
    }
    var photoSubtotal =
      parseFloat(contactPrintsTotal) +
      parseFloat(diapositivesTotal) +
      parseFloat(scanTotal);
    {
      /* lab discount */
    }
    var discount =
      parseFloat(photoSubtotal) * parseFloat(this.state.lab_discount);
    var photoPrice = parseFloat(photoSubtotal) - parseFloat(discount);
    {
      /* end of lab discount */
    }
    this.state.lab_cost = photoPrice;

    {
      /* plots cost */
    }
    var plotQty = parseInt(this.state.plots);
    var plotCp = parseInt(this.state.plot_cp);
    var plotPrice = parseInt(plotQty) * parseInt(plotCp);
    var plotTotal = plotPrice;

    {
      /* delivery cost */
    }
    var deliveryQty = parseInt(this.state.delivery);
    var deliveryCp = parseInt(this.state.delivery_cp);
    var deliveryPrice = parseInt(deliveryQty) * parseInt(deliveryCp);
    var deliveryTotal = deliveryPrice;

    {
      /* imagery cost */
    }
    var imageSubtotal = parseInt(plotTotal) + parseInt(deliveryTotal);
    {
      /* image discount */
    }
    var imgDiscount =
      parseInt(imageSubtotal) * parseFloat(this.state.img_discount);
    var imgPrice = parseInt(imageSubtotal) - parseInt(imgDiscount);
    {
      /* end of image discount */
    }
    this.state.img_cost = imgPrice;

    {
      /* quote cost */
    }
    var quoteCost =
      parseInt(photoPrice) +
      parseInt(imgPrice) +
      parseFloat(this.state.total_adjustment);

    {
      /* bonus percentage calculation */
    }
    if (this.state.bonus_perc != null) {
      var bonus_percent = parseFloat(this.state.bonus_perc);
      var bonus_amount = bonus_percent / 100;
      var bonus_total = bonus_amount * quoteCost;
    } else {
      var bonus_total = 0;
    }

    // Check if total_adjustment has cents by seeing if it has a decimal part
    var hasCents = parseFloat(this.state.total_adjustment) % 1 !== 0;

    // Conditional check: round up only if total_adjustment has no cents
    if (hasCents) {
      // Do not round if there are cents in total_adjustment
      this.state.quote_cost = (quoteCost + bonus_total).toFixed(2);
    } else {
      // Round to nearest dollar if there are no cents
      this.state.quote_cost = Math.ceil(quoteCost + bonus_total).toFixed(
        2
      );
    }

    return (
      <div className="card">
        <div className="card-body">
          <h4 className="mb-4 text-4xl">
            {this.state.editing ? "Update Scan Quote" : "Create Scan Quote"}
          </h4>
          <small>
            (* represents a <strong>required field</strong>)
          </small>
          <form onSubmit={this.handleFormSubmit}>
            <div className="row">
              <div className="col-md-3">
                <label>Quote Type *</label>
                <Select
                  name="quote_type"
                  disabled={true}
                  value={this.state.quote_type}
                  options={[
                    { value: "Film", label: "Film" },
                    { value: "Digital", label: "Digital" },
                    { value: "LiDAR", label: "LiDAR" },
                    { value: "Scan", label: "Scan" },
                    { value: "Plot", label: "Plot" },
                    { value: "Survey", label: "Survey" },
                    { value: "Other", label: "Other" },
                  ]}
                />
              </div>
            </div>
            <p />
            <h5>Photo Lab</h5>
            <hr />
            <div className="row">
              <div className="col">
                <label>Contact Prints *</label>
                <input
                  type="text"
                  name="contact_prints"
                  required
                  value={this.state.contact_prints}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Order *</label>
                <input type="checkbox" className="form-control" />
              </div>
              <div className="col">
                <label>Cost Per *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="contact_prints_cp"
                    required
                    value={this.state.contact_prints_cp}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={contactPrintsTotal}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Diapositives *</label>
                <input
                  type="text"
                  name="diapositives"
                  required
                  value={this.state.diapositives}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col"></div>
              <div className="col">
                <label>Cost Per *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="diapositives_cp"
                    required
                    value={this.state.diapositives_cp}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={diapositivesTotal}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Scanning *</label>
                <input
                  type="text"
                  name="scan_qty"
                  required
                  value={this.state.scan_qty}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col"></div>
              <div className="col">
                <label>Cost Per *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="scan_cp"
                    required
                    value={this.state.scan_cp}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={scanTotal}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Subtotal *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={photoSubtotal}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Discount *</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    name="lab_discount"
                    required
                    value={this.state.lab_discount}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                  <span className="input-group-text">%</span>
                </div>
              </div>
              <div className="col">
                <label>Lab Cost *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="lab_cost"
                    required
                    value={this.state.lab_cost}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Lab Notes</label>
                <textarea
                  name="lab_notes"
                  value={this.state.lab_notes}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <h5>Imagery</h5>
            <hr />
            <div className="row">
              <div className="col">
                <label>Plots *</label>
                <input
                  type="text"
                  name="plots"
                  value={this.state.plots}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Length</label>
                <input
                  type="text"
                  name="plot_x"
                  value={this.state.plot_x}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Width</label>
                <input
                  type="text"
                  name="plot_y"
                  value={this.state.plot_y}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Mounted</label>
                <input
                  type="text"
                  name="plot_mount"
                  value={this.state.plot_mount}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Cost Per</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="plot_cp"
                    value={this.state.plot_cp}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    value={plotTotal}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Discount *</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    name="img_discount"
                    value={this.state.img_discount}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                  <span className="input-group-text">%</span>
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Delivery</label>
                <input
                  type="text"
                  name="delivery"
                  value={this.state.delivery}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Delivery Type</label>
                <Select.Creatable
                  allowCreate={true}
                  name="delivery_type"
                  value={this.state.delivery_type}
                  onChange={this.handleSelectChange("delivery_type")}
                  options={this.state.delivery_type_options}
                />
              </div>
              <div className="col"></div>
              <div className="col">
                <label>Cost Per</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="delivery_cp"
                    value={this.state.delivery_cp}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    value={deliveryTotal}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Imagery Cost</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="img_cost"
                    value={this.state.img_cost}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-2">
                <label>Total Adjustment</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="total_adjustment"
                    required
                    value={this.state.total_adjustment}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <label>Explanation</label>
                <input
                  type="text"
                  name="adjustment_reason"
                  value={this.state.adjustment_reason}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-2">
                <label>Bonus Percentage</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">%</span>
                  <input
                    type="number"
                    step="0.1"
                    min="0"
                    max="2.5"
                    name="bonus_perc"
                    required
                    value={this.state.bonus_perc || 0}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <h5>Total Quote Cost</h5>
            <hr />
            <div className="row">
              <div className="col">
                <label>Quote Cost</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="quote_cost"
                    required
                    value={this.state.quote_cost}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <input
              type="submit"
              value={this.state.editing ? "Update" : "Create"}
              className="btn btn-secondary btn-sm"
            />
            <a
              className="btn btn-danger btn-sm"
              onClick={this.props.history.goBack}
            >
              Cancel
            </a>
          </form>
          <br />
        </div>
      </div>
    );
  }

}
