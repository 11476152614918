import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import 'react-select/dist/react-select.css';

export default class QuoteForm extends React.Component {
  static propTypes = {
    handleNewQuote: PropTypes.func
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      bonus_perc: 0,
      total_adjustment: 0,
      adjustment_reason: "",
      lidar_notes: "",
      client_survey: "",
      plot_laminate: "",
      specification: props.specification,
      scan_qty: this.props.scanQty,
      analytics_qty: this.props.analyticsQty,
      photogrammetry_qty: this.props.photogrammetryQty,
      cartography_qty: this.props.cartographyQty,
      img_qty: this.props.imgQty,
      order_cp: "No",
      order_diap: "No",
      order_xsec: "No",
      lidar_flight_sub: "",
      lidar_flight_sub_options: [
        { value: "Cooper Aerial", label: "Cooper Aerial" },
        { value: "Geomni", label: "Geomni" },
        { value: "Verisk", label: "Verisk" },
        { value: "NAAS", label: "NAAS" },
        { value: "Kucera", label: "Kucera" },
        { value: "SAM", label: "SAM" },
        { value: "CSTI", label: "CSTI" },
        { value: "Terra Flight", label: "Terra Flight" },
        { value: "Talos Aviation", label: "Talos Aviation" },
        { value: "Paragon", label: "Paragon" },
        { value: "Berry and Associates", label: "Berry and Associates" },
        { value: "Aerial Surveys Int'l", label: "Aerial Surveys Int'l" },
        { value: "G Squared", label: "G Squared" },
        { value: "SurvTech Solutions", label: "SurvTech Solutions" },
        { value: "Sitech", label: "Sitech" },
        { value: "Atlantic Group", label: "Atlantic Group" },
        { value: "Aero-Graphics", label: "Aero-Graphics" },
        { value: "Keystone Aerial", label: "Keystone Aerial" },
        { value: "American Aerial", label: "American Aerial" },
        { value: "Valley Air", label: "Valley Air" },
        { value: "Air Flight SVCS", label: "Air Flight SVCS" },
        {
          value: "Skyview Aerial Photo (South CA)",
          label: "Skyview Aerial Photo (South CA)",
        },
        { value: "Midwest Aerial Photo", label: "Midwest Aerial Photo" },
        { value: "Gammair", label: "Gammair" },
        {
          value: "Richard Crouse and Associates",
          label: "Richard Crouse and Associates",
        },
        {
          value: "I.F. Rooks & Associates, Inc.",
          label: "I.F. Rooks & Associates, Inc.",
        },
        {
          value: "Aerial Data Service, Inc.",
          label: "Aerial Data Service, Inc.",
        },
        { value: "Aerodata, Inc.", label: "Aerodata, Inc." },
        { value: "Lasermap USA", label: "Lasermap USA" },
        { value: "Aerials, Inc.", label: "Aerials, Inc." },
        {
          value: "Gulf Coast Aerial Mapping",
          label: "Gulf Coast Aerial Mapping",
        },
        { value: "Air Photographics, Inc.", label: "Air Photographics, Inc." },
        { value: "Ace Aerial", label: "Ace Aerial" },
        { value: "Georgia Aerial Surveys", label: "Georgia Aerial Surveys" },
        {
          value: "Krawietz Aerial Photo Service",
          label: "Krawietz Aerial Photo Service",
        },
        {
          value: "Continental Aerial Surveys Inc.",
          label: "Continental Aerial Surveys Inc.",
        },
        { value: "Aerial Imaging Inc.", label: "Aerial Imaging Inc." },
        {
          value: "McClintock Land Associates, Inc.",
          label: "McClintock Land Associates, Inc.",
        },
        { value: "Eagle Aerial Solutions", label: "Eagle Aerial Solutions" },
        {
          value: "Rocky Mountain Aerial Surveys",
          label: "Rocky Mountain Aerial Surveys",
        },
        { value: "Blue Skies Consulting", label: "Blue Skies Consulting" },
        { value: "GeoTerra", label: "GeoTerra" },
        { value: "Quantum Spatial", label: "Quantum Spatial" },
        { value: "Aerocon", label: "Aerocon" },
        { value: "Pickett Aerial", label: "Pickett Aerial" },
        { value: "Airborne Sensing", label: "Airborne Sensing" },
        { value: "MAP RES", label: "MAP RES" },
        { value: "Predictive Service", label: "Predictive Service" },
        { value: "Wilson Company", label: "Wilson Company" },
        { value: "KBM, Inc.", label: "KBM, Inc." },
        { value: "Air Flight Service", label: "Air Flight Service" },
        { value: "Lidar Services LLC", label: "Lidar Services LLC" },
        { value: "Eagle Mapping", label: "Eagle Mapping" },
      ],
      lidar_flight_quote: "0",
      lidar_flight_markup: "0",
      lidar_flight_cost: "",
      lidar_size: "0",
      size_type: "Acres",
      detail_level: "0",
      lidar_hours: "0",
      lidar_ppsm: "0",
      lidar_bare_earth: "",
      flight_sub: "",
      flight_sub_options: [
        { value: "Cooper Aerial", label: "Cooper Aerial" },
        { value: "Geomni", label: "Geomni" },
        { value: "Verisk", label: "Verisk" },
        { value: "NAAS", label: "NAAS" },
        { value: "SWAS", label: "SWAS" },
        { value: "SAM", label: "SAM" },
        { value: "CSTI", label: "CSTI" },
        { value: "Kucera", label: "Kucera" },
        { value: "Paragon", label: "Paragon" },
        { value: "Terra Flight", label: "Terra Flight" },
        { value: "Berry and Associates", label: "Berry and Associates" },
        { value: "Aerial Surveys Int'l", label: "Aerial Surveys Int'l" },
        { value: "G Squared", label: "G Squared" },
        { value: "SurvTech Solutions", label: "SurvTech Solutions" },
        { value: "Sitech", label: "Sitech" },
        { value: "Atlantic Group", label: "Atlantic Group" },
        { value: "Aero-Graphics", label: "Aero-Graphics" },
        { value: "Keystone Aerial", label: "Keystone Aerial" },
        { value: "American Aerial", label: "American Aerial" },
        { value: "Radman Aerial", label: "Radman Aerial" },
        { value: "Valley Air", label: "Valley Air" },
        { value: "Air Flight SVCS", label: "Air Flight SVCS" },
        {
          value: "Skyview Aerial Photo (South CA)",
          label: "Skyview Aerial Photo (South CA)",
        },
        { value: "Midwest Aerial Photo", label: "Midwest Aerial Photo" },
        { value: "Gammair", label: "Gammair" },
        {
          value: "Richard Crouse and Associates",
          label: "Richard Crouse and Associates",
        },
        {
          value: "I.F. Rooks & Associates, Inc.",
          label: "I.F. Rooks & Associates, Inc.",
        },
        {
          value: "Aerial Data Service, Inc.",
          label: "Aerial Data Service, Inc.",
        },
        { value: "Aerodata, Inc.", label: "Aerodata, Inc." },
        { value: "Lasermap USA", label: "Lasermap USA" },
        { value: "Aerials, Inc.", label: "Aerials, Inc." },
        {
          value: "Gulf Coast Aerial Mapping",
          label: "Gulf Coast Aerial Mapping",
        },
        { value: "Air Photographics, Inc.", label: "Air Photographics, Inc." },
        { value: "Ace Aerial", label: "Ace Aerial" },
        { value: "Georgia Aerial Surveys", label: "Georgia Aerial Surveys" },
        {
          value: "Krawietz Aerial Photo Service",
          label: "Krawietz Aerial Photo Service",
        },
        {
          value: "Continental Aerial Surveys Inc.",
          label: "Continental Aerial Surveys Inc.",
        },
        { value: "Aerial Imaging Inc.", label: "Aerial Imaging Inc." },
        {
          value: "McClintock Land Associates, Inc.",
          label: "McClintock Land Associates, Inc.",
        },
        { value: "Eagle Aerial Solutions", label: "Eagle Aerial Solutions" },
        {
          value: "Rocky Mountain Aerial Surveys",
          label: "Rocky Mountain Aerial Surveys",
        },
        { value: "Blue Skies Consulting", label: "Blue Skies Consulting" },
        { value: "GeoTerra", label: "GeoTerra" },
        { value: "Quantum Spatial", label: "Quantum Spatial" },
        { value: "Aerocon", label: "Aerocon" },
        { value: "Pickett Aerial", label: "Pickett Aerial" },
        { value: "Airborne Sensing", label: "Airborne Sensing" },
        { value: "MAP RES", label: "MAP RES" },
        { value: "Predictive Service", label: "Predictive Service" },
        { value: "Wilson Company", label: "Wilson Company" },
        { value: "KBM, Inc.", label: "KBM, Inc." },
        { value: "Air Flight Service", label: "Air Flight Service" },
        { value: "Lidar Services LLC", label: "Lidar Services LLC" },
        { value: "Eagle Mapping", label: "Eagle Mapping" },
      ],
      flight_quote: "",
      flight_markup: "",
      flight_cost: "",
      flight_notes: "",
      panels: "",
      abgps: "",
      survey_sub: "",
      survey_cost: "",
      survey_quote: "",
      survey_markup: "",
      survey_notes: "",
      contact_prints: this.props.contactPrints,
      contact_prints_cp: "10",
      diapositives: this.props.diaPositives,
      diapositives_cp: "15",
      scan_cp: "20",
      lab_discount: "",
      lab_cost: "",
      lab_notes: "",
      analytics_hp: "0.5",
      analytics_cp: "100",
      photogrammetry_cp: "75",
      photogrammetry_hp: "9",
      xsec: this.props.xsecQty || "",
      xsec_cp: "70",
      xsec_hp: "7",
      cartography_cp: "50",
      cartography_hp: "2",
      translation: this.props.translationQty || "",
      translation_cp: "70",
      translation_hp: ".2",
      map_discount: "",
      map_cost: "",
      img_hp: "1",
      img_cp: "90",
      plots: "",
      plot_x: "",
      plot_y: "",
      plot_cp: "50",
      delivery: "",
      delivery_type: "",
      delivery_type_options: [
        { value: "FTP", label: "FTP" },
        { value: "USB", label: "USB" },
        { value: "H/D", label: "H/D" },
        { value: "DVD", label: "DVD" },
      ],
      delivery_cp: "",
      img_discount: "",
      img_cost: "",
      quote_cost: "",
      map_notes: "",
      img_notes: "",
      plot_mount: "",
      camera: "",
      supplemental_survey: "",
      plain_gc: "",
      description: "",
      sales_tax: "",
      unit_price: "",
      shipping: "",
      discount: "",
      check_plot_qty: "",
      check_plot_hp: "",
      check_plot_cp: "",
      georef_qty: "",
      georef_hp: "",
      georef_cp: "",
      georect_qty: "",
      georect_hp: "",
      georect_cp: "",
      dig_plots_qty: "",
      dig_plots_hp: "",
      dig_plots_cp: "",
      photo_enlarge_qty: "",
      photo_enlarge_hp: "",
      photo_enlarge_cp: "",
      gis_qty: "",
      gis_hp: "",
      gis_cp: "",
      lis_qty: "",
      lis_hp: "",
      lis_cp: "",
      isHiddenImageComplete: true,
      editing: false,
      quote_type: this.props.quoteType || "",
      user_id: this.props.userId || "",
      ...props.quote,
    };
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/quotes/${this.props.match.params.id}`,
        dataType: "JSON"
      }).done((data) => {
        this.setState({
          bonus_perc: data.quote.bonus_perc,
          total_adjustment: data.quote.total_adjustment,
          adjustment_reason: data.quote.adjustment_reason,
          lidar_notes: data.quote.lidar_notes,
          client_survey: data.quote.client_survey,
          plot_laminate: data.quote.plot_laminate,
          order_cp: data.quote.order_cp,
          order_diap: data.quote.order_diap,
          order_xsec: data.quote.order_xsec,
          user_id: data.quote.user_id,
          lidar_ppsm: data.quote.lidar_ppsm,
          lidar_bare_earth: data.quote.lidar_bare_earth,
          scan_qty: data.quote.scan_qty,
          analytics_qty: data.quote.analytics_qty,
          cartography_qty: data.quote.cartography_qty,
          photogrammetry_qty: data.quote.photogrammetry_qty,
          img_qty: data.quote.img_qty,
          opportunity_id: data.quote.opportunity_id,
          specification_id: data.quote.specification_id,
          flight_sub: data.quote.flight_sub,
          flight_quote: data.quote.flight_quote,
          flight_markup: data.quote.flight_markup,
          flight_cost: data.quote.flight_cost,
          flight_notes: data.quote.flight_notes,
          panels: data.quote.panels,
          abgps: data.quote.abgps,
          survey_sub: data.quote.survey_sub,
          survey_cost: data.quote.survey_cost,
          survey_quote: data.quote.survey_quote,
          survey_markup: data.quote.survey_markup,
          survey_notes: data.quote.survey_notes,
          contact_prints: data.quote.contact_prints,
          contact_prints_cp: data.quote.contact_prints_cp,
          diapositives: data.quote.diapositives,
          diapositives_cp: data.quote.diapositives_cp,
          scan_cp: data.quote.scan_cp,
          lab_discount: data.quote.lab_discount,
          lab_cost: data.quote.lab_cost,
          lab_notes: data.quote.lab_notes,
          analytics_hp: data.quote.analytics_hp,
          analytics_cp: data.quote.analytics_cp,
          photogrammetry_cp: data.quote.photogrammetry_cp,
          photogrammetry_hp: data.quote.photogrammetry_hp,
          xsec: data.quote.xsec,
          xsec_cp: data.quote.xsec_cp,
          xsec_hp: data.quote.xsec_hp,
          cartography_cp: data.quote.cartography_cp,
          cartography_hp: data.quote.cartography_hp,
          translation: data.quote.translation,
          translation_cp: data.quote.translation_cp,
          translation_hp: data.quote.translation_hp,
          map_discount: data.quote.map_discount,
          map_cost: data.quote.map_cost,
          img_hp: data.quote.img_hp,
          img_cp: data.quote.img_cp,
          plots: data.quote.plots,
          plot_x: data.quote.plot_x,
          plot_y: data.quote.plot_y,
          plot_cp: data.quote.plot_cp,
          delivery: data.quote.delivery,
          delivery_type: data.quote.delivery_type,
          delivery_cp: data.quote.delivery_cp,
          img_discount: data.quote.img_discount,
          img_cost: data.quote.img_cost,
          quote_cost: data.quote.quote_cost,
          map_notes: data.quote.map_notes,
          img_notes: data.quote.img_notes,
          plot_mount: data.quote.plot_mount,
          camera: data.quote.camera,
          supplemental_survey: data.quote.supplemental_survey,
          plain_gc: data.quote.plain_gc,
          description: data.quote.description,
          sales_tax: data.quote.sales_tax,
          unit_price: data.quote.unit_price,
          shipping: data.quote.shipping,
          discount: data.quote.discount,
          quote_type: data.quote.quote_type,
          lidar_flight_sub: data.quote.lidar_flight_sub,
          lidar_flight_quote: data.quote.lidar_flight_quote,
          lidar_flight_markup: data.quote.lidar_flight_markup,
          lidar_flight_cost: data.quote.lidar_flight_cost,
          lidar_size: data.quote.lidar_size,
          size_type: data.quote.size_type,
          detail_level: data.quote.detail_level,
          lidar_hours: data.quote.lidar_hours,
          check_plot_qty: data.quote.check_plot_qty,
          check_plot_hp: data.quote.check_plot_hp,
          check_plot_cp: data.quote.check_plot_cp,
          georef_qty: data.quote.georef_qty,
          georef_hp: data.quote.georef_hp,
          georef_cp: data.quote.georef_cp,
          georect_qty: data.quote.georect_qty,
          georect_hp: data.quote.georect_hp,
          georect_cp: data.quote.georect_cp,
          dig_plots_qty: data.quote.dig_plots_qty,
          dig_plots_hp: data.quote.dig_plots_hp,
          dig_plots_cp: data.quote.dig_plots_cp,
          photo_enlarge_qty: data.quote.photo_enlarge_qty,
          photo_enlarge_hp: data.quote.photo_enlarge_hp,
          photo_enlarge_cp: data.quote.photo_enlarge_cp,
          gis_qty: data.quote.gis_qty,
          gis_hp: data.quote.gis_hp,
          gis_cp: data.quote.gis_cp,
          lis_qty: data.quote.lis_qty,
          lis_hp: data.quote.lis_hp,
          lis_cp: data.quote.lis_cp,
          editing: this.props.match.path === "/quotes/:id/edit",
        });
      });
    }
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ?
      this.updateQuote() :
      this.addQuote();
  }

  updateQuote() {
    const quote = {
      bonus_perc: this.state.bonus_perc,
      total_adjustment: this.state.total_adjustment,
      adjustment_reason: this.state.adjustment_reason,
      lidar_notes: this.state.lidar_notes,
      client_survey: this.state.client_survey,
      plot_laminate: this.state.plot_laminate,
      order_cp: this.state.order_cp,
      order_diap: this.state.order_diap,
      order_xsec: this.state.order_xsec,
      user_id: this.props.userId,
      lidar_ppsm: this.state.lidar_ppsm,
      lidar_bare_earth: this.state.lidar_bare_earth,
      lidar_size: this.state.lidar_size,
      size_type: this.state.size_type,
      detail_level: this.state.detail_level,
      lidar_hours: this.state.lidar_hours,
      scan_qty: this.props.scanQty,
      analytics_qty: this.props.analyticsQty,
      cartography_qty: this.props.cartographyQty,
      photogrammetry_qty: this.props.photogrammetryQty,
      img_qty: this.props.imgQty,
      opportunity_id: this.props.oppId,
      specification_id: this.props.specId,
      quote_type: this.props.quoteType,
      flight_sub: this.state.flight_sub,
      flight_quote: this.state.flight_quote,
      flight_markup: this.state.flight_markup,
      flight_cost: this.state.flight_cost,
      flight_notes: this.state.flight_notes,
      panels: this.state.panels,
      abgps: this.state.abgps,
      survey_sub: this.state.survey_sub,
      survey_cost: this.state.survey_cost,
      survey_quote: this.state.survey_quote,
      survey_markup: this.state.survey_markup,
      survey_notes: this.state.survey_notes,
      contact_prints: this.props.contactPrints,
      contact_prints_cp: this.state.contact_prints_cp,
      diapositives: this.props.diaPositives,
      diapositives_cp: this.state.diapositives_cp,
      scan_cp: this.state.scan_cp,
      lab_discount: this.state.lab_discount,
      lab_cost: this.state.lab_cost,
      lab_notes: this.state.lab_notes,
      analytics_hp: this.state.analytics_hp,
      analytics_cp: this.state.analytics_cp,
      photogrammetry_cp: this.state.photogrammetry_cp,
      photogrammetry_hp: this.state.photogrammetry_hp,
      xsec: this.props.xsecQty,
      xsec_cp: this.state.xsec_cp,
      xsec_hp: this.state.xsec_hp,
      cartography_cp: this.state.cartography_cp,
      cartography_hp: this.state.cartography_hp,
      translation: this.props.translationQty,
      translation_cp: this.state.translation_cp,
      translation_hp: this.state.translation_hp,
      map_discount: this.state.map_discount,
      map_cost: this.state.map_cost,
      img_hp: this.state.img_hp,
      img_cp: this.state.img_cp,
      plots: this.state.plots,
      plot_x: this.state.plot_x,
      plot_y: this.state.plot_y,
      plot_cp: this.state.plot_cp,
      delivery: this.state.delivery,
      delivery_type: this.state.delivery_type,
      delivery_cp: this.state.delivery_cp,
      img_discount: this.state.img_discount,
      img_cost: this.state.img_cost,
      quote_cost: this.state.quote_cost,
      map_notes: this.state.map_notes,
      img_notes: this.state.img_notes,
      plot_mount: this.state.plot_mount,
      camera: this.state.camera,
      supplemental_survey: this.state.supplemental_survey,
      plain_gc: this.state.plain_gc,
      description: this.state.description,
      sales_tax: this.state.sales_tax,
      unit_price: this.state.unit_price,
      shipping: this.state.shipping,
      discount: this.state.discount,
      check_plot_qty: this.state.check_plot_qty,
      check_plot_hp: this.state.check_plot_hp,
      check_plot_cp: this.state.check_plot_cp,
      georef_qty: this.state.georef_qty,
      georef_hp: this.state.georef_hp,
      georef_cp: this.state.georef_cp,
      georect_qty: this.state.georect_qty,
      georect_hp: this.state.georect_hp,
      georect_cp: this.state.georect_cp,
      dig_plots_qty: this.state.dig_plots_qty,
      dig_plots_hp: this.state.dig_plots_hp,
      dig_plots_cp: this.state.dig_plots_cp,
      photo_enlarge_qty: this.state.photo_enlarge_qty,
      photo_enlarge_hp: this.state.photo_enlarge_hp,
      photo_enlarge_cp: this.state.photo_enlarge_cp,
      gis_qty: this.state.gis_qty,
      gis_hp: this.state.gis_hp,
      gis_cp: this.state.gis_cp,
      lis_qty: this.state.lis_qty,
      lis_hp: this.state.lis_hp,
      lis_cp: this.state.lis_cp,
    };
    $.ajax({
      type: "PATCH",
      url: `/quotes/${this.props.match.params.id}`,
      data: { quote: quote }
    })
      .done((data) => {
        console.log('Quote updated!');
      })
      .fail((response) => {
        console.log("did not work");
        alert("Please review the form for errors");
      });
  }

  addQuote() {
    const quote = {
      bonus_perc: this.state.bonus_perc,
      total_adjustment: this.state.total_adjustment,
      adjustment_reason: this.state.adjustment_reason,
      lidar_notes: this.state.lidar_notes,
      client_survey: this.state.client_survey,
      plot_laminate: this.state.plot_laminate,
      order_cp: this.state.order_cp,
      order_diap: this.state.order_diap,
      order_xsec: this.state.order_xsec,
      user_id: this.props.userId,
      lidar_ppsm: this.state.lidar_ppsm,
      lidar_bare_earth: this.state.lidar_bare_earth,
      lidar_size: this.state.lidar_size,
      size_type: this.state.size_type,
      detail_level: this.state.detail_level,
      lidar_hours: this.state.lidar_hours,
      scan_qty: this.props.scanQty,
      analytics_qty: this.props.analyticsQty,
      cartography_qty: this.props.cartographyQty,
      photogrammetry_qty: this.props.photogrammetryQty,
      img_qty: this.props.imgQty,
      opportunity_id: this.props.oppId,
      specification_id: this.props.specId,
      quote_type: this.props.quoteType,
      flight_sub: this.state.flight_sub,
      flight_quote: this.state.flight_quote,
      flight_markup: this.state.flight_markup,
      flight_cost: this.state.flight_cost,
      flight_notes: this.state.flight_notes,
      lidar_flight_sub: this.state.lidar_flight_sub,
      lidar_flight_quote: this.state.lidar_flight_quote,
      lidar_flight_markup: this.state.lidar_flight_markup,
      lidar_flight_cost: this.state.lidar_flight_cost,
      panels: this.state.panels,
      abgps: this.state.abgps,
      survey_sub: this.state.survey_sub,
      survey_cost: this.state.survey_cost,
      survey_quote: this.state.survey_quote,
      survey_markup: this.state.survey_markup,
      survey_notes: this.state.survey_notes,
      contact_prints: this.props.contactPrints,
      contact_prints_cp: this.state.contact_prints_cp,
      diapositives: this.props.diaPositives,
      diapositives_cp: this.state.diapositives_cp,
      scan_cp: this.state.scan_cp,
      lab_discount: this.state.lab_discount,
      lab_cost: this.state.lab_cost,
      lab_notes: this.state.lab_notes,
      analytics_hp: this.state.analytics_hp,
      analytics_cp: this.state.analytics_cp,
      photogrammetry_cp: this.state.photogrammetry_cp,
      photogrammetry_hp: this.state.photogrammetry_hp,
      xsec: this.props.xsecQty,
      xsec_cp: this.state.xsec_cp,
      xsec_hp: this.state.xsec_hp,
      cartography_cp: this.state.cartography_cp,
      cartography_hp: this.state.cartography_hp,
      translation: this.props.translationQty,
      translation_cp: this.state.translation_cp,
      translation_hp: this.state.translation_hp,
      map_discount: this.state.map_discount,
      map_cost: this.state.map_cost,
      img_hp: this.state.img_hp,
      img_cp: this.state.img_cp,
      plots: this.state.plots,
      plot_x: this.state.plot_x,
      plot_y: this.state.plot_y,
      plot_cp: this.state.plot_cp,
      delivery: this.state.delivery,
      delivery_type: this.state.delivery_type,
      delivery_cp: this.state.delivery_cp,
      img_discount: this.state.img_discount,
      img_cost: this.state.img_cost,
      quote_cost: this.state.quote_cost,
      map_notes: this.state.map_notes,
      img_notes: this.state.img_notes,
      plot_mount: this.state.plot_mount,
      camera: this.state.camera,
      supplemental_survey: this.state.supplemental_survey,
      plain_gc: this.state.plain_gc,
      description: this.state.description,
      sales_tax: this.state.sales_tax,
      unit_price: this.state.unit_price,
      shipping: this.state.shipping,
      discount: this.state.discount,
      check_plot_qty: this.state.check_plot_qty,
      check_plot_hp: this.state.check_plot_hp,
      check_plot_cp: this.state.check_plot_cp,
      georef_qty: this.state.georef_qty,
      georef_hp: this.state.georef_hp,
      georef_cp: this.state.georef_cp,
      georect_qty: this.state.georect_qty,
      georect_hp: this.state.georect_hp,
      georect_cp: this.state.georect_cp,
      dig_plots_qty: this.state.dig_plots_qty,
      dig_plots_hp: this.state.dig_plots_hp,
      dig_plots_cp: this.state.dig_plots_cp,
      photo_enlarge_qty: this.state.photo_enlarge_qty,
      photo_enlarge_hp: this.state.photo_enlarge_hp,
      photo_enlarge_cp: this.state.photo_enlarge_cp,
      gis_qty: this.state.gis_qty,
      gis_hp: this.state.gis_hp,
      gis_cp: this.state.gis_cp,
      lis_qty: this.state.lis_qty,
      lis_hp: this.state.lis_hp,
      lis_cp: this.state.lis_cp,
    };
    $.post('/quotes', { quote: quote })
      .done((data) => {
        this.props.handleNewQuote(data);
        console.log("Quote Successfully Created!");
      })
      .fail((response) => {
        console.log("did not work");
        alert("Please review the form for errors");
      });
  }

  deleteQuote = () => {
    if (confirm("Are you sure you want to delete this quote?")) {
      $.ajax({
        type: "DELETE",
        url: `/quotes/${this.props.match.params.id}`,
      })
        .done((data) => {
          this.props.history.push('/quotes');
        })
        .fail((response) => {
          console.log("Quote deletion failed!");
        });
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSelectChange(key) {
    return function ({ value }) {
      this.setState({ [key]: value });
    }.bind(this);
  }

  toggleHiddenImageComplete() {
    this.setState({
      isHiddenImageComplete: !this.state.isHiddenImageComplete
    })
  }

  render() {
    {
      /* flight cost */
    }
    var flightMarkup =
      parseInt(this.state.flight_quote) * parseFloat(this.state.flight_markup);
    var flightCost = flightMarkup / 100 + parseInt(this.state.flight_quote);
    var flightQuoteCost = Math.round(flightCost);
    this.state.flight_cost = flightQuoteCost;

    {
      /* survey cost */
    }
    var surveyMarkup =
      parseInt(this.state.survey_quote) * parseFloat(this.state.survey_markup);
    var surveyCost = surveyMarkup / 100 + parseInt(this.state.survey_quote);
    var surveyQuoteCost = surveyCost;
    this.state.survey_cost = surveyQuoteCost;

    {
      /* contact prints cost */
    }
    var contactPrintsQty = parseInt(this.state.specification.exposures);
    var contactPrintsCp = parseInt(this.state.contact_prints_cp);
    var contactPrintsPrice =
      parseInt(contactPrintsQty) * parseInt(contactPrintsCp);
    var contactPrintsTotal = contactPrintsPrice;

    {
      /* diapositives cost */
    }
    var diapositivesQty = parseInt(this.state.specification.exposures);
    var diapositivesCp = parseInt(this.state.diapositives_cp);
    var diapositivesPrice =
      parseInt(diapositivesQty) * parseInt(diapositivesCp);
    var diapositivesTotal = diapositivesPrice;

    {
      /* scan cost */
    }
    var scanNumber = parseInt(this.state.specification.exposures);
    var scanCp = parseInt(this.state.scan_cp);
    var scanPrice = parseInt(scanNumber) * parseInt(scanCp);
    var scanTotal = scanPrice;

    {
      /* photo subtotal cost */
    }
    if (this.state.order_cp == "Yes" && this.state.order_diap == "No") {
      var photoSubtotal = parseInt(contactPrintsTotal) + parseInt(scanTotal);
    } else if (this.state.order_cp == "Yes" && this.state.order_diap == "Yes") {
      var photoSubtotal =
        parseInt(contactPrintsTotal) +
        parseInt(diapositivesTotal) +
        parseInt(scanTotal);
    } else if (this.state.order_cp == "No" && this.state.order_diap == "Yes") {
      var photoSubtotal = parseInt(diapositivesTotal) + parseInt(scanTotal);
    } else if (this.state.order_cp == "No" && this.state.order_diap == "No") {
      var photoSubtotal = parseInt(scanTotal);
    }
    {
      /* lab discount */
    }
    var discount =
      parseInt(photoSubtotal) * parseFloat(this.state.lab_discount);
    var photoPrice = parseInt(photoSubtotal) - parseInt(discount);
    {
      /* end of lab discount */
    }
    this.state.lab_cost = photoPrice;

    {
      /* check plots cost */
    }
    var checkPlotsNumber = parseInt(this.state.check_plot_qty);
    var checkPlotCp = parseFloat(this.state.check_plot_cp);
    var checkPlotHp = parseInt(this.state.check_plot_hp);
    var checkPlotHours = checkPlotsNumber * checkPlotHp;
    var checkPlotPrice = checkPlotCp * checkPlotHours;
    var checkPlotTotal = checkPlotPrice;

    {
      /* gis cost */
    }
    var gisQty = this.state.gis_qty;
    var gisCp = this.state.gis_cp;
    var gisHp = this.state.gis_hp;
    var gisHours = gisQty * gisHp;
    var gisPrice = gisCp * gisHours;
    var gisTotal = gisPrice;

    {
      /* lis/his cost */
    }
    var lisQty = this.state.lis_qty;
    var lisCp = this.state.lis_cp;
    var lisHp = this.state.lis_hp;
    var lisHours = lisQty * lisHp;
    var lisPrice = lisCp * lisHours;
    var lisTotal = lisPrice;

    {
      /* analytics cost */
    }
    var analyticsNumber = parseInt(this.state.specification.exposures);
    var analyticsCp = parseInt(this.state.analytics_cp);
    var analyticsHp = parseFloat(this.state.analytics_hp);
    var analyticsHours = parseInt(analyticsNumber) * parseFloat(analyticsHp);
    var analyticsPrice = parseInt(analyticsCp) * parseFloat(analyticsHours);
    var analyticsTotal = analyticsPrice;

    {
      /* photogrammetry cost */
    }
    var photogrammetryNumber = parseInt(this.state.specification.models);
    var photogrammetryCp = parseInt(this.state.photogrammetry_cp);
    var photogrammetryHp = parseFloat(this.state.photogrammetry_hp);
    var photogrammetryHours =
      parseInt(photogrammetryNumber) * parseFloat(photogrammetryHp);
    var photogrammetryPrice =
      parseInt(photogrammetryCp) * parseInt(photogrammetryHours);
    var photogrammetryTotal = photogrammetryPrice;

    {
      /* x-section cost */
    }
    var xsecQty = parseInt(this.state.specification.models);
    var xsecCp = parseInt(this.state.xsec_cp);
    var xsecHp = parseFloat(this.state.xsec_hp);
    var xsecHours = parseInt(xsecQty) * parseFloat(xsecHp);
    var xsecPrice = parseInt(xsecCp) * parseInt(xsecHours);
    var xsecTotal = xsecPrice;

    {
      /* cartography cost */
    }
    var cartographyNumber = parseInt(this.state.specification.models);
    var cartographyCp = parseInt(this.state.cartography_cp);
    var cartographyHp = parseFloat(this.state.cartography_hp);
    var cartographyHours =
      parseInt(cartographyNumber) * parseFloat(cartographyHp);
    var cartographyPrice = parseInt(cartographyCp) * parseInt(cartographyHours);
    var cartographyTotal = cartographyPrice;

    {
      /* translation cost */
    }
    var translationQty = parseInt(this.state.specification.models);
    var translationCp = parseInt(this.state.translation_cp);
    var translationHp = parseFloat(this.state.translation_hp);
    var translationHours = parseInt(translationQty) * parseFloat(translationHp);
    var translationPrice = parseInt(translationCp) * parseInt(translationHours);
    var translationTotal = translationPrice;

    {
      /* mapping subtotal cost */
    }
    if (this.state.order_xsec == "Yes") {
      var mapSubtotal =
        lisTotal +
        gisTotal +
        checkPlotTotal +
        parseInt(analyticsTotal) +
        parseInt(photogrammetryTotal) +
        parseInt(xsecTotal) +
        parseInt(cartographyTotal) +
        parseInt(translationTotal);
    } else if (this.state.order_xsec == "No") {
      var mapSubtotal =
        lisTotal +
        gisTotal +
        checkPlotTotal +
        parseInt(analyticsTotal) +
        parseInt(photogrammetryTotal) +
        parseInt(cartographyTotal) +
        parseInt(translationTotal);
    }
    {
      /* map discount */
    }
    var mapDiscount =
      parseInt(mapSubtotal) * parseFloat(this.state.map_discount);
    var mapPrice = parseInt(mapSubtotal) - parseInt(mapDiscount);
    {
      /* end of map discount */
    }
    this.state.map_cost = mapPrice;

    {
      /* georeferencing cost */
    }
    var georefQty = this.state.georef_qty;
    var georefCp = this.state.georef_cp;
    var georefHp = this.state.georef_hp;
    var georefHours = georefQty * georefHp;
    var georefPrice = georefCp * georefHours;
    var georefTotal = georefPrice;

    {
      /* georectification cost */
    }
    var georectQty = this.state.georect_qty;
    var georectCp = this.state.georect_cp;
    var georectHp = this.state.georect_hp;
    var georectHours = georectQty * georectHp;
    var georectPrice = georectCp * georectHours;
    var georectTotal = georectPrice;

    {
      /* digital plots cost */
    }
    var digPlotQty = this.state.dig_plots_qty;
    var digPlotCp = this.state.dig_plots_cp;
    var digPlotHp = this.state.dig_plots_hp;
    var digPlotHours = digPlotQty * digPlotHp;
    var digPlotPrice = digPlotCp * digPlotHours;
    var digPlotTotal = digPlotPrice;

    {
      /* photo enlargement cost */
    }
    var photoEnlargeQty = this.state.photo_enlarge_qty;
    var photoEnlargeCp = this.state.photo_enlarge_cp;
    var photoEnlargeHp = this.state.photo_enlarge_hp;
    var photoEnlargeHours = photoEnlargeQty * photoEnlargeHp;
    var photoEnlargePrice = photoEnlargeCp * photoEnlargeHours;
    var photoEnlargeTotal = photoEnlargePrice;

    {
      /* rectification cost */
    }
    var rectificationQty = parseInt(this.state.specification.exposures);
    var rectificationCp = parseInt(this.state.img_cp);
    var rectificationHp = parseFloat(this.state.img_hp);
    var rectificationHours =
      parseInt(rectificationQty) * parseFloat(rectificationHp);
    var rectificationPrice =
      parseInt(rectificationCp) * parseInt(rectificationHours);
    var rectificationTotal = rectificationPrice;

    {
      /* plots cost */
    }
    var plotQty = parseInt(this.state.plots);
    var plotCp = parseInt(this.state.plot_cp);
    var plotPrice = parseInt(plotQty) * parseInt(plotCp);
    var plotTotal = plotPrice;

    {
      /* delivery cost */
    }
    var deliveryQty = parseInt(this.state.delivery);
    var deliveryCp = parseInt(this.state.delivery_cp);
    var deliveryPrice = parseInt(deliveryQty) * parseInt(deliveryCp);
    var deliveryTotal = deliveryPrice;

    {
      /* imagery cost */
    }
    var imageSubtotal =
      photoEnlargeTotal +
      digPlotTotal +
      georectTotal +
      georefTotal +
      parseInt(rectificationTotal) +
      parseInt(plotTotal) +
      parseInt(deliveryTotal);
    {
      /* image discount */
    }
    var imgDiscount =
      parseInt(imageSubtotal) * parseFloat(this.state.img_discount);
    var imgPrice = parseInt(imageSubtotal) - parseInt(imgDiscount);
    {
      /* end of image discount */
    }
    this.state.img_cost = imgPrice;

    {
      /* lidar flight cost */
    }
    var lidarFlightMarkup =
      parseInt(this.state.lidar_flight_quote) *
      parseFloat(this.state.lidar_flight_markup);
    var lidarFlightCost =
      lidarFlightMarkup / 100 + parseInt(this.state.lidar_flight_quote);
    var lidarFlightQuoteCost = lidarFlightCost;
    this.state.lidar_flight_cost = lidarFlightQuoteCost;

    {
      /* lidar level of detail pricing */
    }
    var detailPrice = parseInt(this.state.detail_level);
    var processHours = parseInt(this.state.lidar_hours);
    var processTotal = parseInt(detailPrice) * parseInt(processHours);
    var processPrice = processTotal.toFixed(2);

    var lidarSubtotal = lidarFlightQuoteCost + processTotal;

    {
      /* quote cost */
    }
    var quoteCost =
      parseInt(flightQuoteCost) +
      parseInt(surveyQuoteCost) +
      parseInt(photoPrice) +
      parseInt(mapPrice) +
      parseInt(imgPrice) +
      parseInt(lidarFlightQuoteCost) +
      parseInt(processPrice) +
      parseFloat(this.state.total_adjustment);

    {
      /* bonus percentage calculation */
    }
    if (this.state.bonus_perc != null) {
      var bonus_percent = parseFloat(this.state.bonus_perc);
      var bonus_amount = bonus_percent / 100;
      var bonus_total = bonus_amount * quoteCost;
    } else {
      var bonus_total = 0;
    }

    // Check if total_adjustment has cents by seeing if it has a decimal part
    var hasCents = parseFloat(this.state.total_adjustment) % 1 !== 0;

    // Conditional check: round up only if total_adjustment has no cents
    if (hasCents) {
      // Do not round if there are cents in total_adjustment
      this.state.quote_cost = (quoteCost + 250 + bonus_total).toFixed(2);
    } else {
      // Round to nearest dollar if there are no cents
      this.state.quote_cost = Math.ceil(quoteCost + 250 + bonus_total).toFixed(
        2
      );
    }

    return (
      <div className="card">
        <div className="card-body">
          <h4 className="mb-4 text-4xl">
            {this.state.editing ? "Update ADOT Quote" : "Create ADOT Quote"}
          </h4>
          <small>
            (* represents a <strong>required field</strong>)
          </small>
          <form onSubmit={this.handleFormSubmit}>
            <div className="row">
              <div className="col-md-3">
                <label>Quote Type *</label>
                <Select
                  name="quote_type"
                  disabled={true}
                  value={this.state.quote_type}
                  options={[
                    { value: "ADOT", label: "ADOT" },
                    { value: "Film", label: "Film" },
                    { value: "Digital", label: "Digital" },
                    { value: "LiDAR", label: "LiDAR" },
                    { value: "Scan", label: "Scan" },
                    { value: "Plot", label: "Plot" },
                    { value: "Survey", label: "Survey" },
                    { value: "Other", label: "Other" },
                  ]}
                />
              </div>
            </div>
            <p />
            <h5>Flight</h5>
            <hr />
            <div className="row">
              <div className="col-md-3">
                <label>Flight Sub *</label>
                <Select.Creatable
                  required
                  allowCreate={true}
                  name="flight_sub"
                  value={this.state.flight_sub}
                  onChange={this.handleSelectChange("flight_sub")}
                  options={this.state.flight_sub_options}
                />
              </div>
              <div className="col-md-3">
                <label>Flight Quote *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="flight_quote"
                    required
                    value={this.state.flight_quote}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <label>Flight Markup *</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    name="flight_markup"
                    required
                    value={this.state.flight_markup}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                  <span className="input-group-text">%</span>
                </div>
              </div>
              <div className="col-md-3">
                <label>Flight Cost *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="flight_cost"
                    required
                    value={flightQuoteCost}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Flight Notes</label>
                <textarea
                  name="flight_notes"
                  value={this.state.flight_notes}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <h5>Survey</h5>
            <hr />
            <div className="row">
              <div className="col-md-4">
                <label>Is Client Doing Survey? *</label>
                <Select
                  required
                  name="client_survey"
                  value={this.state.client_survey}
                  onChange={this.handleSelectChange("client_survey")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                    { value: "Cooper Aerial", label: "Cooper Aerial" },
                  ]}
                />
              </div>
              <div className="col-md-4">
                <label>Panels *</label>
                <input
                  type="text"
                  name="panels"
                  required
                  value={this.state.panels}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-4">
                <label>ABGPS *</label>
                <Select
                  required
                  name="abgps"
                  value={this.state.abgps}
                  onChange={this.handleSelectChange("abgps")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-3">
                <label>Surveyor *</label>
                <input
                  type="text"
                  name="survey_sub"
                  required
                  value={this.state.survey_sub}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-3">
                <label>Survey Quote *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="survey_quote"
                    required
                    value={this.state.survey_quote}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <label>Markup *</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    name="survey_markup"
                    required
                    value={this.state.survey_markup}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                  <span className="input-group-text">%</span>
                </div>
              </div>
              <div className="col-md-3">
                <label>Survey Cost *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="survey_cost"
                    required
                    value={surveyQuoteCost}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Survey Notes</label>
                <textarea
                  name="survey_notes"
                  value={this.state.survey_notes}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <h5>Photo Lab</h5>
            <hr />
            <div className="row">
              <div className="col">
                <label>Contact Prints *</label>
                <input
                  type="text"
                  name="contact_prints"
                  readOnly
                  value={this.state.specification.exposures}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Order *</label>
                <Select
                  required
                  name="order_cp"
                  value={this.state.order_cp}
                  onChange={this.handleSelectChange("order_cp")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
              <div className="col">
                <label>Cost Per *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="contact_prints_cp"
                    required
                    value={this.state.contact_prints_cp}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={contactPrintsTotal}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Subtotal *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={photoSubtotal}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Diapositives *</label>
                <input
                  type="text"
                  name="diapositives"
                  readOnly
                  value={this.state.specification.exposures}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-1">
                <label>Order *</label>
                <Select
                  required
                  name="order_diap"
                  value={this.state.order_diap}
                  onChange={this.handleSelectChange("order_diap")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
              <div className="col">
                <label>Cost Per *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="diapositives_cp"
                    required
                    value={this.state.diapositives_cp}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={diapositivesTotal}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Discount *</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    name="lab_discount"
                    required
                    value={this.state.lab_discount}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                  <span className="input-group-text">%</span>
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Scanning *</label>
                <input
                  type="text"
                  name="scan_qty"
                  readOnly
                  value={this.state.specification.exposures}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col"></div>
              <div className="col">
                <label>Cost Per *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="scan_cp"
                    required
                    value={this.state.scan_cp}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={scanTotal}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Lab Cost *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="lab_cost"
                    required
                    value={this.state.lab_cost}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Lab Notes</label>
                <textarea
                  name="lab_notes"
                  value={this.state.lab_notes}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <h5>Mapping</h5>
            <hr />
            <div className="row">
              <div className="col">
                <label>Check Plots *</label>
                <input
                  type="text"
                  name="check_plot_qty"
                  required
                  value={this.state.check_plot_qty}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours Per *</label>
                <input
                  type="text"
                  name="check_plot_hp"
                  required
                  value={this.state.check_plot_hp}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours *</label>
                <input
                  type="text"
                  required
                  value={checkPlotHours}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Cost Per *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="check_plot_cp"
                    required
                    value={this.state.check_plot_cp}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={checkPlotTotal}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col">
                <label>GIS *</label>
                <input
                  type="text"
                  name="gis_qty"
                  required
                  value={this.state.gis_qty}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours Per *</label>
                <input
                  type="text"
                  name="gis_hp"
                  required
                  value={this.state.gis_hp}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours *</label>
                <input
                  type="text"
                  required
                  value={gisHours}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Cost Per *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="gis_cp"
                    required
                    value={this.state.gis_cp}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={gisTotal}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col">
                <label>HIS/LIS Quantity *</label>
                <input
                  type="text"
                  name="lis_qty"
                  required
                  value={this.state.lis_qty}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours Per *</label>
                <input
                  type="text"
                  name="lis_hp"
                  required
                  value={this.state.lis_hp}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours *</label>
                <input
                  type="text"
                  required
                  value={lisHours}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Cost Per *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="lis_cp"
                    required
                    value={this.state.lis_cp}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={lisTotal}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col">
                <label>Analytics *</label>
                <input
                  type="text"
                  name="analytics_qty"
                  readOnly
                  value={this.state.specification.exposures}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours Per *</label>
                <input
                  type="text"
                  name="analytics_hp"
                  required
                  value={this.state.analytics_hp}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours *</label>
                <input
                  type="text"
                  required
                  value={analyticsHours}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Cost Per *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="analytics_cp"
                    required
                    value={this.state.analytics_cp}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={analyticsTotal}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Photogrammetry *</label>
                <input
                  type="text"
                  name="photogrammetry_qty"
                  readOnly
                  value={this.state.specification.models}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours Per *</label>
                <input
                  type="text"
                  name="photogrammetry_hp"
                  required
                  value={this.state.photogrammetry_hp}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours *</label>
                <input
                  type="text"
                  required
                  value={photogrammetryHours}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Cost Per *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="photogrammetry_cp"
                    required
                    value={this.state.photogrammetry_cp}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={photogrammetryTotal}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>X Sections *</label>
                <input
                  type="text"
                  name="xsec"
                  readOnly
                  value={this.state.specification.models}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Order *</label>
                <Select
                  required
                  name="order_xsec"
                  value={this.state.order_xsec}
                  onChange={this.handleSelectChange("order_xsec")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
              <div className="col">
                <label>Hours Per *</label>
                <input
                  type="text"
                  name="xsec_hp"
                  required
                  value={this.state.xsec_hp}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours *</label>
                <input
                  type="text"
                  required
                  value={xsecHours}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Cost Per *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="xsec_cp"
                    required
                    value={this.state.xsec_cp}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={xsecTotal}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Cartography *</label>
                <input
                  type="text"
                  name="cartography_qty"
                  readOnly
                  value={this.state.specification.models}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours Per *</label>
                <input
                  type="text"
                  name="cartography_hp"
                  required
                  value={this.state.cartography_hp}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours *</label>
                <input
                  type="text"
                  required
                  value={cartographyHours}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Cost Per *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="cartography_cp"
                    required
                    value={this.state.cartography_cp}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={cartographyTotal}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Translation *</label>
                <input
                  type="text"
                  name="translation"
                  readOnly
                  value={this.state.specification.models}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours Per *</label>
                <input
                  type="text"
                  name="translation_hp"
                  required
                  value={this.state.translation_hp}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours *</label>
                <input
                  type="text"
                  required
                  value={translationHours}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Cost Per *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="translation_cp"
                    required
                    value={this.state.translation_cp}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={translationTotal}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Subtotal *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={mapSubtotal}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Discount *</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    name="map_discount"
                    required
                    value={this.state.map_discount}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                  <span className="input-group-text">%</span>
                </div>
              </div>
              <div className="col">
                <label>Mapping Cost *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="map_cost"
                    required
                    value={this.state.map_cost}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Map Notes</label>
                <textarea
                  name="map_notes"
                  value={this.state.map_notes}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <h5>Imagery</h5>
            <hr />
            <div className="row">
              <div className="col">
                <label>Georeferenced *</label>
                <input
                  type="text"
                  name="georef_qty"
                  required
                  value={this.state.georef_qty}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours Per</label>
                <input
                  type="text"
                  name="georef_hp"
                  required
                  value={this.state.georef_hp}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours</label>
                <input
                  type="text"
                  required
                  value={georefHours}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Cost Per</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="georef_cp"
                    required
                    value={this.state.georef_cp}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={georefTotal}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col">
                <label>Georectified *</label>
                <input
                  type="text"
                  name="georect_qty"
                  required
                  value={this.state.georect_qty}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours Per</label>
                <input
                  type="text"
                  name="georect_hp"
                  required
                  value={this.state.georect_hp}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours</label>
                <input
                  type="text"
                  required
                  value={georectHours}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Cost Per</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="georect_cp"
                    required
                    value={this.state.georect_cp}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={georectTotal}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col">
                <label>Digital Plots *</label>
                <input
                  type="text"
                  name="dig_plots_qty"
                  required
                  value={this.state.dig_plots_qty}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours Per</label>
                <input
                  type="text"
                  name="dig_plots_hp"
                  required
                  value={this.state.dig_plots_hp}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours</label>
                <input
                  type="text"
                  required
                  value={digPlotHours}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Cost Per</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="dig_plots_cp"
                    required
                    value={this.state.dig_plots_cp}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={digPlotTotal}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col">
                <label>Photo Enlargement *</label>
                <input
                  type="text"
                  name="photo_enlarge_qty"
                  required
                  value={this.state.photo_enlarge_qty}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours Per</label>
                <input
                  type="text"
                  name="photo_enlarge_hp"
                  required
                  value={this.state.photo_enlarge_hp}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours</label>
                <input
                  type="text"
                  required
                  value={photoEnlargeHours}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Cost Per</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="photo_enlarge_cp"
                    required
                    value={this.state.photo_enlarge_cp}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={photoEnlargeTotal}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col">
                <label>Rectification *</label>
                <input
                  type="text"
                  name="img_qty"
                  readOnly
                  value={this.state.specification.exposures}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours Per</label>
                <input
                  type="text"
                  name="img_hp"
                  required
                  value={this.state.img_hp}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours</label>
                <input
                  type="text"
                  required
                  value={rectificationHours}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Cost Per</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="img_cp"
                    required
                    value={this.state.img_cp}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={rectificationTotal}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Subtotal *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={imageSubtotal}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Plots *</label>
                <input
                  type="text"
                  name="plots"
                  required
                  value={this.state.plots}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Length</label>
                <input
                  type="text"
                  name="plot_x"
                  value={this.state.plot_x}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Width</label>
                <input
                  type="text"
                  name="plot_y"
                  value={this.state.plot_y}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Mounted</label>
                <Select
                  name="plot_mount"
                  value={this.state.plot_mount}
                  onChange={this.handleSelectChange("plot_mount")}
                  options={[
                    { value: "None", label: "None" },
                    { value: "1/2", label: "1/2" },
                    { value: "3/16", label: "3/16" },
                  ]}
                />
              </div>
              <div className="col">
                <label>Laminated</label>
                <Select
                  name="plot_laminate"
                  value={this.state.plot_laminate}
                  onChange={this.handleSelectChange("plot_laminate")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
              <div className="col">
                <label>Cost Per</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="plot_cp"
                    required
                    value={this.state.plot_cp}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={plotTotal}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Discount *</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    name="img_discount"
                    required
                    value={this.state.img_discount}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                  <span className="input-group-text">%</span>
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Delivery</label>
                <input
                  type="text"
                  name="delivery"
                  required
                  value={this.state.delivery}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Delivery Type</label>
                <Select.Creatable
                  allowCreate={true}
                  name="delivery_type"
                  value={this.state.delivery_type}
                  onChange={this.handleSelectChange("delivery_type")}
                  options={this.state.delivery_type_options}
                />
              </div>
              <div className="col"></div>
              <div className="col">
                <label>Cost Per</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="delivery_cp"
                    required
                    value={this.state.delivery_cp}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={deliveryTotal}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Imagery Cost</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="img_cost"
                    required
                    value={this.state.img_cost}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <button
              type="button"
              onClick={this.toggleHiddenImageComplete.bind(this)}
              className="btn btn-secondary btn-sm"
            >
              {this.state.isHiddenImageComplete
                ? "Expand LiDAR Section +"
                : "Hide LiDAR Section -"}
            </button>
            <p />
            {!this.state.isHiddenImageComplete && (
              <div>
                <h5>LiDAR</h5>
                <hr />
                <div className="row">
                  <div className="col-md-3">
                    <label>LiDAR Flight Sub *</label>
                    <Select.Creatable
                      allowCreate={true}
                      name="lidar_flight_sub"
                      value={this.state.lidar_flight_sub}
                      onChange={this.handleSelectChange("lidar_flight_sub")}
                      options={this.state.lidar_flight_sub_options}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>LiDAR Flight Quote *</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        name="lidar_flight_quote"
                        value={this.state.lidar_flight_quote}
                        onChange={this.handleChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label>LiDAR Flight Markup *</label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        name="lidar_flight_markup"
                        value={this.state.lidar_flight_markup}
                        onChange={this.handleChange}
                        className="form-control"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label>LiDAR Flight Cost *</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        name="lidar_flight_cost"
                        value={lidarFlightQuoteCost}
                        onChange={this.handleChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col-md-3">
                    <label>Size</label>
                    <input
                      type="text"
                      name="lidar_size"
                      value={this.state.lidar_size}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Size Type</label>
                    <Select
                      name="size_type"
                      value={this.state.size_type}
                      onChange={this.handleSelectChange("size_type")}
                      options={[
                        { value: "Acres", label: "Acres" },
                        { value: "Square Miles", label: "Square Miles" },
                        { value: "Linear Feet", label: "Linear Feet" },
                      ]}
                    />
                  </div>
                  <div className="col-md-2">
                    <label>L.O.D. (cost per hour)</label>
                    <Select
                      name="detail_level"
                      value={this.state.detail_level}
                      onChange={this.handleSelectChange("detail_level")}
                      options={[
                        { value: "50", label: "1" },
                        { value: "70", label: "2" },
                        { value: "90", label: "3" },
                      ]}
                    />
                  </div>
                  <div className="col-md-2">
                    <label>Hours</label>
                    <input
                      type="text"
                      name="lidar_hours"
                      value={this.state.lidar_hours}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-2">
                    <label>Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        value={processPrice}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col-md-3">
                    <label>PPSM *</label>
                    <input
                      type="text"
                      name="lidar_ppsm"
                      placeholder="PPSM"
                      value={this.state.lidar_ppsm}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Bare Earth Only *</label>
                    <Select
                      name="lidar_bare_earth"
                      value={this.state.lidar_bare_earth}
                      onChange={this.handleSelectChange("lidar_bare_earth")}
                      options={[
                        { value: "No", label: "No" },
                        { value: "Yes", label: "Yes" },
                      ]}
                    />
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col-md-3">
                    <label>LiDAR Total *</label>
                    <input
                      type="text"
                      value={lidarSubtotal}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>LiDAR Notes</label>
                    <textarea
                      name="lidar_notes"
                      value={this.state.lidar_notes}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            )}
            <p />
            <div className="row">
              <div className="col-md-2">
                <label>Total Adjustment</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="total_adjustment"
                    required
                    value={this.state.total_adjustment}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <label>Explanation</label>
                <input
                  type="text"
                  name="adjustment_reason"
                  value={this.state.adjustment_reason}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-2">
                <label>Bonus Percentage</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">%</span>
                  <input
                    type="number"
                    step="0.1"
                    min="0"
                    max="2.5"
                    name="bonus_perc"
                    required
                    value={this.state.bonus_perc || 0}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <h5>Total Quote Cost</h5>
            <hr />
            <div className="row">
              <div className="col-md-2">
                <label>Quote Cost</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="quote_cost"
                    required
                    value={this.state.quote_cost}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <input
              type="submit"
              value={this.state.editing ? "Update" : "Create"}
              className="btn btn-secondary btn-sm"
            />
            <a
              className="btn btn-danger btn-sm"
              onClick={this.props.history.goBack}
            >
              Cancel
            </a>
          </form>
          <br />
        </div>
      </div>
    );
  }

}
