import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import ContactOpportunityForm from './ContactOpportunityForm';
import update from 'immutability-helper';

export default class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      contact: props.contact,
      office: props.office,
      company: props.company,
      opportunities: [],
      isHidden: true
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden
    })
  }

  static propTypes = {
    contact: PropTypes.object
  }

  static defaultProps = {
    contact: {}
  }

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/contacts/${this.props.match.params.id}`,
        dataType: "JSON"
      }).done((data) => {
        this.setState({ data });
      })
    }
  }

  addNewOpportunity = (opportunity) => {
    const opportunities = update(this.state.opportunities, { $push: [opportunity]});
    this.setState({
      opportunities: opportunities.sort(function(a,b){
        return new Date(a.created_at) - new Date(b.created_at);
      })
    });
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <form>
            {/* Contact Details Section */}
            <h5 className="border-bottom pb-2 mb-4">Contact Details</h5>
            <div className="row">
              <div className="col-md-4 mb-3">
                <label className="fw-bold">Client Rep</label>
                <p>{this.state.contact.client_rep || "None entered"}</p>
              </div>
              <div className="col-md-4 mb-3">
                <label className="fw-bold">Email</label>
                <p>{this.state.contact.email || "Not provided"}</p>
              </div>
              <div className="col-md-4 mb-3">
                <label className="fw-bold">Accounting Email</label>
                <p>{this.state.contact.accounting_email || "Not provided"}</p>
              </div>
              <div className="col-md-4 mb-3">
                <label className="fw-bold">Position</label>
                <p>{this.state.contact.position || "Not specified"}</p>
              </div>
              <div className="col-md-4 mb-3">
                <label className="fw-bold">Contact Alert</label>
                <p>{this.state.contact.contact_alert || "None"}</p>
              </div>
            </div>

            {/* Office Details Section */}
            <h5 className="border-bottom pb-2 mb-4">Office Details</h5>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="fw-bold">Office Address</label>
                <p>
                  {this.state.office.address || "No address"}
                  <br />
                  {this.state.office.city}, {this.state.office.state}{" "}
                  {this.state.office.zip}
                </p>
              </div>
              <div className="col-md-4 mb-3">
                <label className="fw-bold">Office Phone</label>
                <p>
                  {this.state.contact.phone
                    ? this.state.contact.phone.replace(
                        /(\d{3})(\d{3})(\d{4})/,
                        "($1) $2-$3"
                      )
                    : "Not provided"}
                  {this.state.contact.ext && ` Ext: ${this.state.contact.ext}`}
                </p>
              </div>
              {this.state.contact.mobile && (
                <div className="col-md-4 mb-3">
                  <label className="fw-bold">Mobile</label>
                  <p>
                    {this.state.contact.mobile.replace(
                      /(\d{3})(\d{3})(\d{4})/,
                      "($1) $2-$3"
                    )}
                  </p>
                </div>
              )}
            </div>

            {/* Billing Information Section */}
            {this.state.contact.add_billing_info === "Yes" && (
              <>
                <h5 className="border-bottom pb-2 mt-4 mb-4">
                  Billing Information
                </h5>
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <label className="fw-bold">Billing Contact</label>
                    <p>
                      {this.state.contact.billing_contact || "Not provided"}
                    </p>
                  </div>
                  <div className="col-md-4 mb-3">
                    <label className="fw-bold">Billing Email</label>
                    <p>{this.state.contact.billing_email || "Not provided"}</p>
                  </div>
                  <div className="col-md-4 mb-3">
                    <label className="fw-bold">Billing Phone</label>
                    <p>
                      {this.state.contact.billing_phone
                        ? this.state.contact.billing_phone.replace(
                            /(\d{3})(\d{3})(\d{4})/,
                            "($1) $2-$3"
                          )
                        : "Not provided"}
                    </p>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="fw-bold">Billing Address</label>
                    <p>
                      {this.state.contact.billing_address || "Not provided"}
                      <br />
                      {this.state.contact.billing_city},{" "}
                      {this.state.contact.billing_state}{" "}
                      {this.state.contact.billing_zip}
                    </p>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="fw-bold">Billing Notes</label>
                    <p>{this.state.contact.billing_notes || "None"}</p>
                  </div>
                </div>
              </>
            )}

            {/* Add Opportunity Button */}
            {this.state.contact.active_client && (
              <button
                title="Click to expand/hide create opportunity form"
                type="button"
                onClick={this.toggleHidden.bind(this)}
                className="btn btn-secondary btn-sm mt-4"
              >
                {this.state.isHidden ? (
                  <span>
                    Add Opportunity{" "}
                    <i className="fa fa-plus-circle" aria-hidden="true"></i>
                  </span>
                ) : (
                  <span>
                    Close Opportunity Form{" "}
                    <i className="fa fa-minus-circle" aria-hidden="true"></i>
                  </span>
                )}
              </button>
            )}

            {/* Opportunity Form */}
            {!this.state.isHidden && (
              <ContactOpportunityForm
                handleNewOpportunity={this.addNewOpportunity}
                contactId={this.props.match.params.id}
              />
            )}
          </form>
        </div>
      </div>
    );
  }
}
