import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["estimateTotal", "adjustedPricing", "contractCost"];

  connect() {
    this.calculateTotal(); // Initial calculation when the form loads
  }

  adjust() {
    this.calculateTotal();
  }

  calculateTotal() {
    const estimateTotal = parseFloat(this.estimateTotalTarget.value) || 0;
    const adjustedPricing = parseFloat(this.adjustedPricingTarget.value) || 0;

    // Calculate the new contract cost and display it
    const newContractCost = estimateTotal + adjustedPricing;
    this.contractCostTarget.value = newContractCost.toFixed(2);
  }
}
