import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import 'react-select/dist/react-select.css';

export default class QuoteForm extends React.Component {
  static propTypes = {
    handleNewQuote: PropTypes.func
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      bonus_perc: 0,
      total_adjustment: 0,
      adjustment_reason: '',
      lidar_notes: '',
      client_survey: '',
      plot_laminate: '',
      lidar_flight_sub: '',
      lidar_flight_quote: '0',
      lidar_flight_markup: '0',
      lidar_flight_cost: '0',
      lidar_size: '',
      size_type: 'Acres',
      detail_level: '',
      lidar_hours: '0',
      flight_sub: '',
      flight_quote: '0',
      flight_markup: '0',
      flight_cost: '0',
      flight_notes: '',
      panels: '0',
      abgps: 'No',
      survey_sub: '',
      survey_cost: '',
      survey_quote: '0',
      survey_markup: '0',
      survey_notes: '',
      contact_prints: '',
      contact_prints_cp: '',
      diapositives: '',
      diapositives_cp: '',
      scan_cp: '',
      lab_discount: '0',
      lab_cost: '',
      lab_notes: '',
      analytics_hp: '',
      analytics_cp: '',
      photogrammetry_cp: '',
      photogrammetry_hp: '',
      xsec: '',
      xsec_cp: '',
      xsec_hp: '',
      cartography_qty: '',
      cartography_cp: '0',
      cartography_hp: '0',
      translation: '0',
      translation_cp: '0',
      translation_hp: '0',
      map_discount: '0',
      map_cost: '',
      img_hp: '1',
      img_cp: '20',
      plots: '0',
      plot_x: '',
      plot_y: '',
      plot_cp: '0',
      delivery: '0',
      delivery_type: '',
      delivery_type_options: [
        { value: 'FTP', label: 'FTP' },
        { value: 'USB', label: 'USB' },
        { value: 'H/D', label: 'H/D' },
        { value: 'DVD', label: 'DVD' },
      ],
      delivery_cp: '0',
      img_discount: '0',
      img_cost: '0',
      quote_cost: '',
      map_notes: '',
      img_notes: '',
      plot_mount: '',
      camera: '',
      supplemental_survey: '',
      plain_gc: '',
      description: '',
      sales_tax: '',
      unit_price: '',
      shipping: '',
      discount: '0',
      editing: false,
    }
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/quotes/${this.props.match.params.id}`,
        dataType: "JSON"
      }).done((data) => {
        this.setState({
          bonus_perc: data.quote.bonus_perc,
          total_adjustment: data.quote.total_adjustment,
          adjustment_reason: data.quote.adjustment_reason,
          lidar_notes: data.quote.lidar_notes,
          client_survey: data.quote.client_survey,
          plot_laminate: data.quote.plot_laminate,
          user_id: data.quote.user_id,
          lidar_size: data.quote.lidar_size,
          size_type: data.quote.size_type,
          detail_level: data.quote.detail_level,
          lidar_hours: data.quote.lidar_hours,
          opportunity_id: data.quote.opportunity_id,
          specification_id: data.quote.specification_id,
          flight_sub: data.quote.flight_sub,
          flight_quote: data.quote.flight_quote,
          flight_markup: data.quote.flight_markup,
          flight_cost: data.quote.flight_cost,
          flight_notes: data.quote.flight_notes,
          panels: data.quote.panels,
          abgps: data.quote.abgps,
          survey_sub: data.quote.survey_sub,
          survey_cost: data.quote.survey_cost,
          survey_quote: data.quote.survey_quote,
          survey_markup: data.quote.survey_markup,
          survey_notes: data.quote.survey_notes,
          contact_prints: data.quote.contact_prints,
          contact_prints_cp: data.quote.contact_prints_cp,
          diapositives: data.quote.diapositives,
          diapositives_cp: data.quote.diapositives_cp,
          scan_cp: data.quote.scan_cp,
          lab_discount: data.quote.lab_discount,
          lab_cost: data.quote.lab_cost,
          lab_notes: data.quote.lab_notes,
          analytics_hp: data.quote.analytics_hp,
          analytics_cp: data.quote.analytics_cp,
          photogrammetry_cp: data.quote.photogrammetry_cp,
          photogrammetry_hp: data.quote.photogrammetry_hp,
          xsec: data.quote.xsec,
          xsec_cp: data.quote.xsec_cp,
          xsec_hp: data.quote.xsec_hp,
          cartography_qty: data.quote.cartography_qty,
          cartography_cp: data.quote.cartography_cp,
          cartography_hp: data.quote.cartography_hp,
          translation: data.quote.translation,
          translation_cp: data.quote.translation_cp,
          translation_hp: data.quote.translation_hp,
          map_discount: data.quote.map_discount,
          map_cost: data.quote.map_cost,
          img_qty: data.quote.img_qty,
          img_hp: data.quote.img_hp,
          img_cp: data.quote.img_cp,
          plots: data.quote.plots,
          plot_x: data.quote.plot_x,
          plot_y: data.quote.plot_y,
          plot_cp: data.quote.plot_cp,
          delivery: data.quote.delivery,
          delivery_type: data.quote.delivery_type,
          delivery_cp: data.quote.delivery_cp,
          img_discount: data.quote.img_discount,
          img_cost: data.quote.img_cost,
          quote_cost: data.quote.quote_cost,
          map_notes: data.quote.map_notes,
          img_notes: data.quote.img_notes,
          plot_mount: data.quote.plot_mount,
          camera: data.quote.camera,
          supplemental_survey: data.quote.supplemental_survey,
          plain_gc: data.quote.plain_gc,
          description: data.quote.description,
          sales_tax: data.quote.sales_tax,
          unit_price: data.quote.unit_price,
          shipping: data.quote.shipping,
          discount: data.quote.discount,
          quote_type: data.quote.quote_type,
          lidar_flight_sub: data.quote.lidar_flight_sub,
          lidar_flight_quote: data.quote.lidar_flight_quote,
          lidar_flight_markup: data.quote.lidar_flight_markup,
          lidar_flight_cost: data.quote.lidar_flight_cost,
          editing: this.props.match.path === "/quotes/:id/edit",
        });
      });
    }
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ?
      this.updateQuote() :
      this.addQuote();
  }

  updateQuote() {
    const quote = {
      bonus_perc: this.state.bonus_perc,
      total_adjustment: this.state.total_adjustment,
      adjustment_reason: this.state.adjustment_reason,
      lidar_notes: this.state.lidar_notes,
      client_survey: this.state.client_survey,
      plot_laminate: this.state.plot_laminate,
      user_id: this.props.userId,
      lidar_size: this.props.areaSize,
      size_type: this.props.sizeType,
      detail_level: this.props.detailLevel,
      lidar_hours: this.state.lidar_hours,
      opportunity_id: this.props.oppId,
      specification_id: this.props.specId,
      quote_type: this.props.quoteType,
      flight_sub: this.state.flight_sub,
      flight_quote: this.state.flight_quote,
      flight_markup: this.state.flight_markup,
      flight_cost: this.state.flight_cost,
      flight_notes: this.state.flight_notes,
      lidar_flight_sub: this.state.lidar_flight_sub,
      lidar_flight_quote: this.state.lidar_flight_quote,
      lidar_flight_markup: this.state.lidar_flight_markup,
      lidar_flight_cost: this.state.lidar_flight_cost,
      panels: this.state.panels,
      abgps: this.state.abgps,
      survey_sub: this.state.survey_sub,
      survey_cost: this.state.survey_cost,
      survey_quote: this.state.survey_quote,
      survey_markup: this.state.survey_markup,
      survey_notes: this.state.survey_notes,
      contact_prints: this.state.contact_prints,
      contact_prints_cp: this.state.contact_prints_cp,
      diapositives: this.state.diapositives,
      diapositives_cp: this.state.diapositives_cp,
      scan_cp: this.state.scan_cp,
      lab_discount: this.state.lab_discount,
      lab_cost: this.state.lab_cost,
      lab_notes: this.state.lab_notes,
      analytics_hp: this.state.analytics_hp,
      analytics_cp: this.state.analytics_cp,
      photogrammetry_cp: this.state.photogrammetry_cp,
      photogrammetry_hp: this.state.photogrammetry_hp,
      xsec: this.state.xsec,
      xsec_cp: this.state.xsec_cp,
      xsec_hp: this.state.xsec_hp,
      cartography_qty: this.state.cartography_qty,
      cartography_cp: this.state.cartography_cp,
      cartography_hp: this.state.cartography_hp,
      translation: this.state.translation,
      translation_cp: this.state.translation_cp,
      translation_hp: this.state.translation_hp,
      map_discount: this.state.map_discount,
      map_cost: this.state.map_cost,
      img_qty: this.props.rectificationQty,
      img_hp: this.state.img_hp,
      img_cp: this.state.img_cp,
      plots: this.state.plots,
      plot_x: this.state.plot_x,
      plot_y: this.state.plot_y,
      plot_cp: this.state.plot_cp,
      delivery: this.state.delivery,
      delivery_type: this.state.delivery_type,
      delivery_cp: this.state.delivery_cp,
      img_discount: this.state.img_discount,
      img_cost: this.state.img_cost,
      quote_cost: this.state.quote_cost,
      map_notes: this.state.map_notes,
      img_notes: this.state.img_notes,
      plot_mount: this.state.plot_mount,
      camera: this.state.camera,
      supplemental_survey: this.state.supplemental_survey,
      plain_gc: this.state.plain_gc,
      description: this.state.description,
      sales_tax: this.state.sales_tax,
      unit_price: this.state.unit_price,
      shipping: this.state.shipping,
      discount: this.state.discount,
    };
    $.ajax({
      type: "PATCH",
      url: `/quotes/${this.props.match.params.id}`,
      data: { quote: quote }
    })
      .done((data) => {
        console.log('Quote updated!');
      })
      .fail((response) => {
        console.log("did not work");
        alert("Please review the form for errors");
      });
  }

  addQuote() {
    const quote = {
      bonus_perc: this.state.bonus_perc,
      total_adjustment: this.state.total_adjustment,
      adjustment_reason: this.state.adjustment_reason,
      lidar_notes: this.state.lidar_notes,
      client_survey: this.state.client_survey,
      plot_laminate: this.state.plot_laminate,
      user_id: this.props.userId,
      lidar_size: this.props.areaSize,
      size_type: this.props.sizeType,
      detail_level: this.props.detailLevel,
      lidar_hours: this.state.lidar_hours,
      opportunity_id: this.props.oppId,
      specification_id: this.props.specId,
      quote_type: this.props.quoteType,
      flight_sub: this.state.flight_sub,
      flight_quote: this.state.flight_quote,
      flight_markup: this.state.flight_markup,
      flight_cost: this.state.flight_cost,
      flight_notes: this.state.flight_notes,
      lidar_flight_sub: this.state.lidar_flight_sub,
      lidar_flight_quote: this.state.lidar_flight_quote,
      lidar_flight_markup: this.state.lidar_flight_markup,
      lidar_flight_cost: this.state.lidar_flight_cost,
      panels: this.state.panels,
      abgps: this.state.abgps,
      survey_sub: this.state.survey_sub,
      survey_cost: this.state.survey_cost,
      survey_quote: this.state.survey_quote,
      survey_markup: this.state.survey_markup,
      survey_notes: this.state.survey_notes,
      contact_prints: this.state.contact_prints,
      contact_prints_cp: this.state.contact_prints_cp,
      diapositives: this.state.diapositives,
      diapositives_cp: this.state.diapositives_cp,
      scan_cp: this.state.scan_cp,
      lab_discount: this.state.lab_discount,
      lab_cost: this.state.lab_cost,
      lab_notes: this.state.lab_notes,
      analytics_hp: this.state.analytics_hp,
      analytics_cp: this.state.analytics_cp,
      photogrammetry_cp: this.state.photogrammetry_cp,
      photogrammetry_hp: this.state.photogrammetry_hp,
      xsec: this.state.xsec,
      xsec_cp: this.state.xsec_cp,
      xsec_hp: this.state.xsec_hp,
      cartography_qty: this.state.cartography_qty,
      cartography_cp: this.state.cartography_cp,
      cartography_hp: this.state.cartography_hp,
      translation: this.state.translation,
      translation_cp: this.state.translation_cp,
      translation_hp: this.state.translation_hp,
      map_discount: this.state.map_discount,
      map_cost: this.state.map_cost,
      img_qty: this.props.rectificationQty,
      img_hp: this.state.img_hp,
      img_cp: this.state.img_cp,
      plots: this.state.plots,
      plot_x: this.state.plot_x,
      plot_y: this.state.plot_y,
      plot_cp: this.state.plot_cp,
      delivery: this.state.delivery,
      delivery_type: this.state.delivery_type,
      delivery_cp: this.state.delivery_cp,
      img_discount: this.state.img_discount,
      img_cost: this.state.img_cost,
      quote_cost: this.state.quote_cost,
      map_notes: this.state.map_notes,
      img_notes: this.state.img_notes,
      plot_mount: this.state.plot_mount,
      camera: this.state.camera,
      supplemental_survey: this.state.supplemental_survey,
      plain_gc: this.state.plain_gc,
      description: this.state.description,
      sales_tax: this.state.sales_tax,
      unit_price: this.state.unit_price,
      shipping: this.state.shipping,
      discount: this.state.discount,
    };
    $.post('/quotes', { quote: quote })
      .done((data) => {
        this.props.handleNewQuote(data);
        console.log("Quote Successfully Created!");
      })
      .fail((response) => {
        console.log("did not work");
        alert("Please review the form for errors");
      });
  }

  deleteQuote = () => {
    if (confirm("Are you sure you want to delete this quote?")) {
      $.ajax({
        type: "DELETE",
        url: `/quotes/${this.props.match.params.id}`,
      })
        .done((data) => {
          this.props.history.push('/quotes');
        })
        .fail((response) => {
          console.log("Quote deletion failed!");
        });
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSelectChange(key) {
    return function ({ value }) {
      this.setState({ [key]: value });
    }.bind(this);
  }

  toggleHiddenFlightComplete() {
    this.setState({
      isHiddenFlightComplete: !this.state.isHiddenFlightComplete
    })
  }

  toggleHiddenSurveyComplete() {
    this.setState({
      isHiddenSurveyComplete: !this.state.isHiddenSurveyComplete
    })
  }

  toggleHiddenImageComplete() {
    this.setState({
      isHiddenImageComplete: !this.state.isHiddenImageComplete
    })
  }

  render() {
    {
      /* lidar level of detail pricing */
    }
    var detailPrice = parseInt(this.state.detail_level);
    var processHours = parseInt(this.state.lidar_hours);
    var processTotal = parseInt(detailPrice) * parseInt(processHours);
    var processPrice = processTotal.toFixed(2);

    {
      /* cartography cost */
    }
    var cartographyNumber = parseInt(this.state.cartography_qty);
    var cartographyCp = parseInt(this.state.cartography_cp);
    var cartographyHp = parseFloat(this.state.cartography_hp);
    var cartographyHours =
      parseInt(cartographyNumber) * parseFloat(cartographyHp);
    var cartographyPrice = parseInt(cartographyCp) * parseInt(cartographyHours);
    var cartographyTotal = cartographyPrice;

    {
      /* translation cost */
    }
    var translationQty = parseInt(this.state.translation);
    var translationCp = parseInt(this.state.translation_cp);
    var translationHp = parseFloat(this.state.translation_hp);
    var translationHours = parseInt(translationQty) * parseFloat(translationHp);
    var translationPrice = parseInt(translationCp) * parseInt(translationHours);
    var translationTotal = translationPrice;

    {
      /* mapping subtotal cost */
    }
    var mapSubtotal =
      parseInt(cartographyTotal) +
      parseInt(translationTotal) +
      parseInt(processPrice);
    {
      /* map discount */
    }
    var mapDiscount =
      parseInt(mapSubtotal) * parseFloat(this.state.map_discount);
    var mapPrice = parseInt(mapSubtotal) - parseInt(mapDiscount);
    {
      /* end of map discount */
    }
    this.state.map_cost = mapPrice;

    {
      /* quote cost */
    }
    var quoteCost =
      parseInt(mapPrice) + parseFloat(this.state.total_adjustment);

    {
      /* bonus percentage calculation */
    }
    if (this.state.bonus_perc != null) {
      var bonus_percent = parseFloat(this.state.bonus_perc);
      var bonus_amount = bonus_percent / 100;
      var bonus_total = bonus_amount * quoteCost;
    } else {
      var bonus_total = 0;
    }

    // Check if total_adjustment has cents by seeing if it has a decimal part
    var hasCents = parseFloat(this.state.total_adjustment) % 1 !== 0;

    // Conditional check: round up only if total_adjustment has no cents
    if (hasCents) {
      // Do not round if there are cents in total_adjustment
      this.state.quote_cost = (quoteCost + 250 + bonus_total).toFixed(2);
    } else {
      // Round to nearest dollar if there are no cents
      this.state.quote_cost = Math.ceil(quoteCost + 250 + bonus_total).toFixed(
        2
      );
    }

    return (
      <div className="card">
        <div className="card-body">
          <h4 className="mb-4 text-4xl">
            {this.state.editing
              ? "Update LiDAR Processing Quote"
              : "Create LiDAR Processing Quote"}
          </h4>
          <small>
            (* represents a <strong>required field</strong>)
          </small>
          <form onSubmit={this.handleFormSubmit}>
            <div className="row">
              <div className="col-md-3">
                <label>Quote Type *</label>
                <Select
                  name="quote_type"
                  disabled={true}
                  value={this.state.quote_type}
                  options={[
                    { value: "Film", label: "Film" },
                    { value: "Digital", label: "Digital" },
                    { value: "LiDAR", label: "LiDAR" },
                    { value: "LiDAR Processing", label: "LiDAR Processing" },
                    { value: "Scan", label: "Scan" },
                    { value: "Plot", label: "Plot" },
                    { value: "Survey", label: "Survey" },
                    { value: "Other", label: "Other" },
                  ]}
                />
              </div>
            </div>
            <p />
            <h5>Processing</h5>
            <hr />
            <div className="row">
              <div className="col">
                <label>Size</label>
                <input
                  type="text"
                  name="lidar_size"
                  value={this.state.lidar_size}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Size Type</label>
                <input
                  type="text"
                  name="size_type"
                  value={this.state.size_type}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>L.O.D. (cost per hour)</label>
                <input
                  type="text"
                  name="detail_level"
                  value={this.state.detail_level}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours</label>
                <input
                  type="text"
                  name="lidar_hours"
                  value={this.state.lidar_hours}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Total</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    value={processPrice}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Cartography *</label>
                <input
                  type="text"
                  name="cartography_qty"
                  value={this.state.cartography_qty}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours Per *</label>
                <input
                  type="text"
                  name="cartography_hp"
                  value={this.state.cartography_hp}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours *</label>
                <input
                  type="text"
                  value={cartographyHours}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Cost Per *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="cartography_cp"
                    value={this.state.cartography_cp}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    value={cartographyTotal}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Translation *</label>
                <input
                  type="text"
                  name="translation"
                  value={this.state.translation}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours Per *</label>
                <input
                  type="text"
                  name="translation_hp"
                  value={this.state.translation_hp}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours *</label>
                <input
                  type="text"
                  value={translationHours}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Cost Per *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="translation_cp"
                    value={this.state.translation_cp}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    value={translationTotal}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Subtotal *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    value={mapSubtotal}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Discount *</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    name="map_discount"
                    value={this.state.map_discount}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                  <span className="input-group-text">%</span>
                </div>
              </div>
              <div className="col">
                <label>Processing Cost *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="map_cost"
                    value={this.state.map_cost}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Processing Notes</label>
                <textarea
                  name="map_notes"
                  value={this.state.map_notes}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-2">
                <label>Total Adjustment</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="total_adjustment"
                    value={this.state.total_adjustment}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <label>Explanation</label>
                <input
                  type="text"
                  name="adjustment_reason"
                  value={this.state.adjustment_reason}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-2">
                <label>Bonus Percentage</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">%</span>
                  <input
                    type="number"
                    step="0.1"
                    min="0"
                    max="2.5"
                    name="bonus_perc"
                    required
                    value={this.state.bonus_perc || 0}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <h5>Total Quote Cost</h5>
            <hr />
            <div className="row">
              <div className="col">
                <label>Quote Cost</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="quote_cost"
                    value={this.state.quote_cost}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <input
              type="submit"
              value={this.state.editing ? "Update" : "Create"}
              className="btn btn-secondary btn-sm"
            />
            <a
              className="btn btn-danger btn-sm"
              onClick={this.props.history.goBack}
            >
              Cancel
            </a>
          </form>
          <br />
        </div>
      </div>
    );
  }

}
