import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "hours",
    "rate",
    "total",
    "totalProductionCost",
    "surveyCost",
    "surveyMarkup",
    "surveyTotal",
    "lidarFlightCost",
    "lidarFlightMarkup",
    "lidarFlightTotal",
    "imageryFlightCost",
    "imageryFlightMarkup",
    "imageryFlightTotal",
    "uavFlightCost",
    "uavFlightMarkup",
    "uavFlightTotal",
    "expediteTotal",
    "adminTotal",
    "projectTotal",
  ];

  connect() {
    this.updateTotals();
  }

  updateTotals() {
    let totalProductionCost = 0;

    this.hoursTargets.forEach((element, index) => {
      const hours = parseFloat(element.value) || 0;
      const rate = parseFloat(this.rateTargets[index].value) || 0;
      const total = hours * rate;

      this.totalTargets[index].value = total.toFixed(2);
      totalProductionCost += total;
    });

    this.totalProductionCostTarget.value = totalProductionCost.toFixed(2);

    const surveyCost = parseFloat(this.surveyCostTarget.value) || 0;
    const surveyMarkup = parseFloat(this.surveyMarkupTarget.value) || 0;
    let surveyTotal = surveyCost + surveyCost * (surveyMarkup / 100);
    surveyTotal = Math.ceil(surveyTotal); // Round up
    this.surveyTotalTarget.value = surveyTotal;

    const lidarFlightCost = parseFloat(this.lidarFlightCostTarget.value) || 0;
    const lidarFlightMarkup =
      parseFloat(this.lidarFlightMarkupTarget.value) || 0;
    let lidarFlightTotal =
      lidarFlightCost + lidarFlightCost * (lidarFlightMarkup / 100);
    lidarFlightTotal = Math.ceil(lidarFlightTotal); // Round up
    this.lidarFlightTotalTarget.value = lidarFlightTotal;

    const imageryFlightCost =
      parseFloat(this.imageryFlightCostTarget.value) || 0;
    const imageryFlightMarkup =
      parseFloat(this.imageryFlightMarkupTarget.value) || 0;
    let imageryFlightTotal =
      imageryFlightCost + imageryFlightCost * (imageryFlightMarkup / 100);
    imageryFlightTotal = Math.ceil(imageryFlightTotal); // Round up
    this.imageryFlightTotalTarget.value = imageryFlightTotal;

    const uavFlightCost = parseFloat(this.uavFlightCostTarget.value) || 0;
    const uavFlightMarkup = parseFloat(this.uavFlightMarkupTarget.value) || 0;
    let uavFlightTotal =
      uavFlightCost + uavFlightCost * (uavFlightMarkup / 100);
    uavFlightTotal = Math.ceil(uavFlightTotal); // Round up
    this.uavFlightTotalTarget.value = uavFlightTotal;

    const expediteTotal = Math.ceil(
      parseFloat(this.expediteTotalTarget.value) || 0
    ); // Round up

    // Calculate the initial project total without adminTotal
    const initialProjectTotal =
      totalProductionCost +
      surveyTotal +
      lidarFlightTotal +
      imageryFlightTotal +
      uavFlightTotal +
      expediteTotal;

    // Calculate adminTotal as 2.5% of initialProjectTotal
    let adminTotal = initialProjectTotal * 0.025;
    adminTotal = Math.ceil(adminTotal); // Round up
    this.adminTotalTarget.value = adminTotal;

    // Calculate the final project total including adminTotal
    const finalProjectTotal = Math.ceil(initialProjectTotal + adminTotal); // Round up
    this.projectTotalTarget.value = finalProjectTotal;
  }
}
