import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import 'react-select/dist/react-select.css';

export default class QuoteForm extends React.Component {
  static propTypes = {
    handleNewQuote: PropTypes.func
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      bonus_perc: 2.5,
      isHiddenServices: false,
      isHidden: false,
      registered_land_surveyor: "0",
      rls_hrs: 0,
      rls_cp: 160,
      survey_party_chief: "0",
      spc_hrs: 0,
      spc_cp: 120,
      survey_tech: "0",
      st_hrs: 0,
      st_cp: 100,
      survey_crew2: "0",
      sc2_hrs: 0,
      sc2_cp: 165,
      survey_crew3: "0",
      sc3_hrs: 0,
      sc3_cp: 200,
      rw_plans_tech: "0",
      rw_plans_hrs: 0,
      rw_plans_cp: 95,
      total_adjustment: 0,
      adjustment_reason: "",
      client_survey: "No",
      flight_sub: "",
      flight_sub_options: [
        { value: "Cooper Aerial", label: "Cooper Aerial" },
        { value: "Geomni", label: "Geomni" },
        { value: "Verisk", label: "Verisk" },
        { value: "NAAS", label: "NAAS" },
        { value: "SWAS", label: "SWAS" },
        { value: "SAM", label: "SAM" },
        { value: "CSTI", label: "CSTI" },
        { value: "Kucera", label: "Kucera" },
        { value: "Terra Flight", label: "Terra Flight" },
        { value: "Talos Aviation", label: "Talos Aviation" },
        { value: "Paragon", label: "Paragon" },
        { value: "Berry and Associates", label: "Berry and Associates" },
        { value: "Aerial Surveys Int'l", label: "Aerial Surveys Int'l" },
        { value: "G Squared", label: "G Squared" },
        { value: "SurvTech Solutions", label: "SurvTech Solutions" },
        { value: "Sitech", label: "Sitech" },
        { value: "Atlantic Group", label: "Atlantic Group" },
        { value: "Aero-Graphics", label: "Aero-Graphics" },
        { value: "Keystone Aerial", label: "Keystone Aerial" },
        { value: "American Aerial", label: "American Aerial" },
        { value: "Valley Air", label: "Valley Air" },
        { value: "Radman Aerial", label: "Radman Aerial" },
        { value: "Air Flight SVCS", label: "Air Flight SVCS" },
        {
          value: "Skyview Aerial Photo (South CA)",
          label: "Skyview Aerial Photo (South CA)",
        },
        { value: "Midwest Aerial Photo", label: "Midwest Aerial Photo" },
        { value: "Gammair", label: "Gammair" },
        {
          value: "Richard Crouse and Associates",
          label: "Richard Crouse and Associates",
        },
        {
          value: "I.F. Rooks & Associates, Inc.",
          label: "I.F. Rooks & Associates, Inc.",
        },
        {
          value: "Aerial Data Service, Inc.",
          label: "Aerial Data Service, Inc.",
        },
        { value: "Aerodata, Inc.", label: "Aerodata, Inc." },
        { value: "Lasermap USA", label: "Lasermap USA" },
        { value: "Aerials, Inc.", label: "Aerials, Inc." },
        {
          value: "Gulf Coast Aerial Mapping",
          label: "Gulf Coast Aerial Mapping",
        },
        { value: "Air Photographics, Inc.", label: "Air Photographics, Inc." },
        { value: "Ace Aerial", label: "Ace Aerial" },
        { value: "Georgia Aerial Surveys", label: "Georgia Aerial Surveys" },
        {
          value: "Krawietz Aerial Photo Service",
          label: "Krawietz Aerial Photo Service",
        },
        {
          value: "Continental Aerial Surveys Inc.",
          label: "Continental Aerial Surveys Inc.",
        },
        { value: "Aerial Imaging Inc.", label: "Aerial Imaging Inc." },
        {
          value: "McClintock Land Associates, Inc.",
          label: "McClintock Land Associates, Inc.",
        },
        { value: "Eagle Aerial Solutions", label: "Eagle Aerial Solutions" },
        {
          value: "Rocky Mountain Aerial Surveys",
          label: "Rocky Mountain Aerial Surveys",
        },
        { value: "Blue Skies Consulting", label: "Blue Skies Consulting" },
        { value: "GeoTerra", label: "GeoTerra" },
        { value: "Quantum Spatial", label: "Quantum Spatial" },
        { value: "Aerocon", label: "Aerocon" },
        { value: "Pickett Aerial", label: "Pickett Aerial" },
        { value: "Airborne Sensing", label: "Airborne Sensing" },
        { value: "MAP RES", label: "MAP RES" },
        { value: "Predictive Service", label: "Predictive Service" },
        { value: "Wilson Company", label: "Wilson Company" },
        { value: "KBM, Inc.", label: "KBM, Inc." },
        { value: "Air Flight Service", label: "Air Flight Service" },
        { value: "Lidar Services LLC", label: "Lidar Services LLC" },
        { value: "Eagle Mapping", label: "Eagle Mapping" },
      ],
      flight_quote: "0",
      flight_markup: "0",
      flight_cost: "0",
      flight_notes: "",
      panels: "0",
      abgps: "No",
      survey_sub: "",
      survey_cost: "0",
      survey_quote: "0",
      survey_markup: "0",
      survey_notes: "",
      contact_prints: "0",
      contact_prints_cp: "0",
      diapositives: "0",
      diapositives_cp: "0",
      scan_cp: "0",
      lab_discount: "0",
      lab_cost: "0",
      lab_notes: "",
      analytics_hp: "0",
      analytics_cp: "0",
      photogrammetry_cp: "0",
      photogrammetry_hp: "0",
      xsec: "0",
      xsec_cp: "0",
      xsec_hp: "0",
      cartography_cp: "0",
      cartography_hp: "0",
      translation: "0",
      translation_cp: "0",
      translation_hp: "0",
      map_discount: "0",
      map_cost: "0",
      img_hp: "0",
      img_cp: "0",
      plots: "0",
      plot_x: "",
      plot_y: "",
      plot_cp: "",
      delivery: "",
      delivery_type: "",
      delivery_type_options: [
        { value: "FTP", label: "FTP" },
        { value: "USB", label: "USB" },
        { value: "H/D", label: "H/D" },
        { value: "DVD", label: "DVD" },
      ],
      delivery_cp: "0",
      img_discount: "0",
      img_cost: "0",
      quote_cost: "",
      map_notes: "",
      img_notes: "",
      plot_mount: "",
      camera: "",
      supplemental_survey: "",
      plain_gc: "",
      description: "",
      sales_tax: "0",
      unit_price: "0",
      shipping: "0",
      discount: "0",
      editing: false,
    };
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if(this.props.match) {
    $.ajax({
      type: "GET",
      url: `/quotes/${this.props.match.params.id}`,
      dataType: "JSON"
    }).done((data) => {
      this.setState({
        bonus_perc: data.quote.bonus_perc,
        registered_land_surveyor: data.quote.registered_land_surveyor,
        rls_hrs: data.quote.rls_hrs,
        rls_cp: data.quote.rls_cp,
        survey_party_chief: data.quote.survey_party_chief,
        spc_hrs: data.quote.spc_hrs,
        spc_cp: data.qute.spc_cp,
        survey_tech: data.quote.survey_tech,
        st_hrs: data.quote.st_hrs,
        st_cp: data.quote.st_cp,
        survey_crew2: data.quote.survey_crew2,
        sc2_hrs: data.quote.sc2_hrs,
        sc2_cp: data.quote.sc2_cp,
        survey_crew3: data.quote.survey_crew3,
        sc3_hrs: data.quote.sc3_hrs,
        sc3_cp: data.quote.sc3_cp,
        rw_plans_tech: data.quote.rw_plans_tech,
        rw_plans_hrs: data.quote.rw_plans_hrs,
        rw_plans_cp: data.quote.rw_plans_cp,
        total_adjustment: data.quote.total_adjustment,
        adjustment_reason: data.quote.adjustment_reason,
        client_survey: data.quote.client_survey,
        user_id: data.quote.user_id,
        opportunity_id: data.quote.opportunity_id,
        specification_id: data.quote.specification_id,
        flight_sub: data.quote.flight_sub,
        flight_quote: data.quote.flight_quote,
        flight_markup: data.quote.flight_markup,
        flight_cost: data.quote.flight_cost,
        flight_notes: data.quote.flight_notes,
        panels: data.quote.panels,
        abgps: data.quote.abgps,
        survey_sub: data.quote.survey_sub,
        survey_cost: data.quote.survey_cost,
        survey_quote: data.quote.survey_quote,
        survey_markup: data.quote.survey_markup,
        survey_notes: data.quote.survey_notes,
        contact_prints: data.quote.contact_prints,
        contact_prints_cp: data.quote.contact_prints_cp,
        diapositives: data.quote.diapositives,
        diapositives_cp: data.quote.diapositives_cp,
        scan_cp: data.quote.scan_cp,
        lab_discount: data.quote.lab_discount,
        lab_cost: data.quote.lab_cost,
        lab_notes: data.quote.lab_notes,
        analytics_hp: data.quote.analytics_hp,
        analytics_cp: data.quote.analytics_cp,
        photogrammetry_cp: data.quote.photogrammetry_cp,
        photogrammetry_hp: data.quote.photogrammetry_hp,
        xsec: data.quote.xsec,
        xsec_cp: data.quote.xsec_cp,
        xsec_hp: data.quote.xsec_hp,
        cartography_cp: data.quote.cartography_cp,
        cartography_hp: data.quote.cartography_hp,
        translation: data.quote.translation,
        translation_cp: data.quote.translation_cp,
        translation_hp: data.quote.translation_hp,
        map_discount: data.quote.map_discount,
        map_cost: data.quote.map_cost,
        img_hp: data.quote.img_hp,
        img_cp: data.quote.img_cp,
        plots: data.quote.plots,
        plot_x: data.quote.plot_x,
        plot_y: data.quote.plot_y,
        plot_cp: data.quote.plot_cp,
        delivery: data.quote.delivery,
        delivery_type: data.quote.delivery_type,
        delivery_cp: data.quote.delivery_cp,
        img_discount: data.quote.img_discount,
        img_cost: data.quote.img_cost,
        quote_cost: data.quote.quote_cost,
        map_notes: data.quote.map_notes,
        img_notes: data.quote.img_notes,
        plot_mount: data.quote.plot_mount,
        camera: data.quote.camera,
        supplemental_survey: data.quote.supplemental_survey,
        plain_gc: data.quote.plain_gc,
        description: data.quote.description,
        sales_tax: data.quote.sales_tax,
        unit_price: data.quote.unit_price,
        shipping: data.quote.shipping,
        discount: data.quote.discount,
        quote_type: data.quote.quote_type,
        editing: this.props.match.path === "/quotes/:id/edit",
      });
    });
   }
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ?
      this.updateQuote() :
      this.addQuote();
  }

  updateQuote() {
    const quote = {
      bonus_perc: this.state.bonus_perc,
      registered_land_surveyor: this.state.registered_land_surveyor,
      rls_hrs: this.state.rls_hrs,
      rls_cp: this.state.rls_cp,
      survey_party_chief: this.state.survey_party_chief,
      spc_hrs: this.state.spc_hrs,
      spc_cp: this.state.spc_cp,
      survey_tech: this.state.survey_tech,
      st_hrs: this.state.st_hrs,
      st_cp: this.state.st_cp,
      survey_crew2: this.state.survey_crew2,
      sc2_hrs: this.state.sc2_hrs,
      sc2_cp: this.state.sc2_cp,
      survey_crew3: this.state.survey_crew3,
      sc3_hrs: this.state.sc3_hrs,
      sc3_cp: this.state.sc3_cp,
      rw_plans_tech: this.state.rw_plans_tech,
      rw_plans_hrs: this.state.rw_plans_hrs,
      rw_plans_cp: this.state.rw_plans_cp,
      total_adjustment: this.state.total_adjustment,
      adjustment_reason: this.state.adjustment_reason,
      client_survey: this.state.client_survey,
      user_id: this.props.userId,
      opportunity_id: this.props.oppId,
      specification_id: this.props.specId,
      quote_type: this.props.quoteType,
      flight_sub: this.state.flight_sub,
      flight_quote: this.state.flight_quote,
      flight_markup: this.state.flight_markup,
      flight_cost: this.state.flight_cost,
      flight_notes: this.state.flight_notes,
      panels: this.state.panels,
      abgps: this.state.abgps,
      survey_sub: this.props.surveyStaff,
      survey_cost: this.state.survey_cost,
      survey_quote: this.state.survey_quote,
      survey_markup: this.state.survey_markup,
      survey_notes: this.state.survey_notes,
      contact_prints: this.state.contact_prints,
      contact_prints_cp: this.state.contact_prints_cp,
      diapositives: this.state.diapositives,
      diapositives_cp: this.state.diapositives_cp,
      scan_cp: this.state.scan_cp,
      lab_discount: this.state.lab_discount,
      lab_cost: this.state.lab_cost,
      lab_notes: this.state.lab_notes,
      analytics_hp: this.state.analytics_hp,
      analytics_cp: this.state.analytics_cp,
      photogrammetry_cp: this.state.photogrammetry_cp,
      photogrammetry_hp: this.state.photogrammetry_hp,
      xsec: this.state.xsec,
      xsec_cp: this.state.xsec_cp,
      xsec_hp: this.state.xsec_hp,
      cartography_cp: this.state.cartography_cp,
      cartography_hp: this.state.cartography_hp,
      translation: this.state.translation,
      translation_cp: this.state.translation_cp,
      translation_hp: this.state.translation_hp,
      map_discount: this.state.map_discount,
      map_cost: this.state.map_cost,
      img_hp: this.state.img_hp,
      img_cp: this.state.img_cp,
      plots: this.state.plots,
      plot_x: this.state.plot_x,
      plot_y: this.state.plot_y,
      plot_cp: this.state.plot_cp,
      delivery: this.state.delivery,
      delivery_type: this.state.delivery_type,
      delivery_cp: this.state.delivery_cp,
      img_discount: this.state.img_discount,
      img_cost: this.state.img_cost,
      quote_cost: this.state.quote_cost,
      map_notes: this.state.map_notes,
      img_notes: this.state.img_notes,
      plot_mount: this.state.plot_mount,
      camera: this.state.camera,
      supplemental_survey: this.state.supplemental_survey,
      plain_gc: this.state.plain_gc,
      description: this.state.description,
      sales_tax: this.state.sales_tax,
      unit_price: this.state.unit_price,
      shipping: this.state.shipping,
      discount: this.state.discount,
    };
    $.ajax({
      type: "PATCH",
      url: `/quotes/${this.props.match.params.id}`,
      data: { quote: quote }
    })
      .done((data) => {
        console.log('Quote updated!');
      })
      .fail((response) => {
        console.log("did not work");
        alert("Please review the form for errors");
      });
  }

  addQuote() {
    const quote = {
      bonus_perc: this.state.bonus_perc,
      registered_land_surveyor: this.state.registered_land_surveyor,
      rls_hrs: this.state.rls_hrs,
      rls_cp: this.state.rls_cp,
      survey_party_chief: this.state.survey_party_chief,
      spc_hrs: this.state.spc_hrs,
      spc_cp: this.state.spc_cp,
      survey_tech: this.state.survey_tech,
      st_hrs: this.state.st_hrs,
      st_cp: this.state.st_cp,
      survey_crew2: this.state.survey_crew2,
      sc2_hrs: this.state.sc2_hrs,
      sc2_cp: this.state.sc2_cp,
      survey_crew3: this.state.survey_crew3,
      sc3_hrs: this.state.sc3_hrs,
      sc3_cp: this.state.sc3_cp,
      rw_plans_tech: this.state.rw_plans_tech,
      rw_plans_hrs: this.state.rw_plans_hrs,
      rw_plans_cp: this.state.rw_plans_cp,
      total_adjustment: this.state.total_adjustment,
      adjustment_reason: this.state.adjustment_reason,
      client_survey: this.state.client_survey,
      user_id: this.props.userId,
      opportunity_id: this.props.oppId,
      specification_id: this.props.specId,
      quote_type: this.props.quoteType,
      flight_sub: this.state.flight_sub,
      flight_quote: this.state.flight_quote,
      flight_markup: this.state.flight_markup,
      flight_cost: this.state.flight_cost,
      flight_notes: this.state.flight_notes,
      panels: this.state.panels,
      abgps: this.state.abgps,
      survey_sub: this.props.surveyStaff,
      survey_cost: this.state.survey_cost,
      survey_quote: this.state.survey_quote,
      survey_markup: this.state.survey_markup,
      survey_notes: this.state.survey_notes,
      contact_prints: this.state.contact_prints,
      contact_prints_cp: this.state.contact_prints_cp,
      diapositives: this.state.diapositives,
      diapositives_cp: this.state.diapositives_cp,
      scan_cp: this.state.scan_cp,
      lab_discount: this.state.lab_discount,
      lab_cost: this.state.lab_cost,
      lab_notes: this.state.lab_notes,
      analytics_hp: this.state.analytics_hp,
      analytics_cp: this.state.analytics_cp,
      photogrammetry_cp: this.state.photogrammetry_cp,
      photogrammetry_hp: this.state.photogrammetry_hp,
      xsec: this.state.xsec,
      xsec_cp: this.state.xsec_cp,
      xsec_hp: this.state.xsec_hp,
      cartography_cp: this.state.cartography_cp,
      cartography_hp: this.state.cartography_hp,
      translation: this.state.translation,
      translation_cp: this.state.translation_cp,
      translation_hp: this.state.translation_hp,
      map_discount: this.state.map_discount,
      map_cost: this.state.map_cost,
      img_hp: this.state.img_hp,
      img_cp: this.state.img_cp,
      plots: this.state.plots,
      plot_x: this.state.plot_x,
      plot_y: this.state.plot_y,
      plot_cp: this.state.plot_cp,
      delivery: this.state.delivery,
      delivery_type: this.state.delivery_type,
      delivery_cp: this.state.delivery_cp,
      img_discount: this.state.img_discount,
      img_cost: this.state.img_cost,
      quote_cost: this.state.quote_cost,
      map_notes: this.state.map_notes,
      img_notes: this.state.img_notes,
      plot_mount: this.state.plot_mount,
      camera: this.state.camera,
      supplemental_survey: this.state.supplemental_survey,
      plain_gc: this.state.plain_gc,
      description: this.state.description,
      sales_tax: this.state.sales_tax,
      unit_price: this.state.unit_price,
      shipping: this.state.shipping,
      discount: this.state.discount,
    };
    $.post('/quotes', { quote: quote })
      .done((data) => {
        this.props.handleNewQuote(data);
        console.log("Quote Successfully Created!");
      })
      .fail((response) => {
        console.log("did not work");
        alert("Please review the form for errors");
      });
  }

  deleteQuote = () => {
    if (confirm("Are you sure you want to delete this quote?")) {
      $.ajax({
        type: "DELETE",
        url: `/quotes/${this.props.match.params.id}`,
      })
        .done((data) => {
          this.props.history.push('/quotes');
        })
        .fail((response) => {
          console.log("Quote deletion failed!");
        });
    }
  }

  handleChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  handleSelectChange(key) {
    return function ({ value }) {
      this.setState({ [key]: value });
    }.bind(this);
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden
    })
  }

  toggleHiddenServices() {
    this.setState({
      isHiddenServices: !this.state.isHiddenServices
    })
  }

  render() {
    // survey services cost
    var rlsTotal = parseInt(this.state.rls_cp) * parseFloat(this.state.rls_hrs);
    var spcTotal = parseInt(this.state.spc_cp) * parseFloat(this.state.spc_hrs);
    var stTotal = parseInt(this.state.st_cp) * parseFloat(this.state.st_hrs);
    var sc2Total = parseInt(this.state.sc2_cp) * parseFloat(this.state.sc2_hrs);
    var sc3Total = parseInt(this.state.sc3_cp) * parseFloat(this.state.sc3_hrs);
    var rwTotal =
      parseInt(this.state.rw_plans_cp) * parseFloat(this.state.rw_plans_hrs);
    var servicesTotal =
      rlsTotal + spcTotal + stTotal + sc2Total + sc3Total + rwTotal;

    var markup =
      (parseInt(this.state.quote_cost) * parseFloat(this.state.survey_markup)) /
      100;
    var surveyCost =
      parseInt(this.state.survey_quote) * parseInt(this.state.panels);
    this.state.survey_cost = surveyCost;
    var quoteCost =
      surveyCost + servicesTotal + parseFloat(this.state.total_adjustment);

    {
      /* bonus percentage calculation */
    }
    var bonus_percent = parseFloat(this.state.bonus_perc);
    var bonus_amount = bonus_percent / 100;
    var bonus_total = bonus_amount * quoteCost;

    // Check if total_adjustment has cents by seeing if it has a decimal part
    var hasCents = parseFloat(this.state.total_adjustment) % 1 !== 0;

    // Conditional check: round up only if total_adjustment has no cents
    if (hasCents) {
      // Do not round if there are cents in total_adjustment
      this.state.quote_cost = (quoteCost + bonus_total).toFixed(2);
    } else {
      // Round to nearest dollar if there are no cents
      this.state.quote_cost = Math.ceil(quoteCost + bonus_total).toFixed(
        2
      );
    }

    return (
      <div className="card">
        <div className="card-body">
          <h4 className="mb-4 text-4xl">
            {this.state.editing ? "Update Survey Quote" : "Create Survey Quote"}
          </h4>
          <small>
            (* represents a <strong>required field</strong>)
          </small>
          <form onSubmit={this.handleFormSubmit}>
            <div className="row">
              <div className="col-md-4">
                <label>Surveyor *</label>
                <input
                  type="text"
                  name="survey_sub"
                  required
                  value={this.props.surveyStaff}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-4">
                <label>Quote Type *</label>
                <Select
                  name="quote_type"
                  disabled={true}
                  value={this.props.quoteType}
                  options={[
                    { value: "Film", label: "Film" },
                    { value: "Digital", label: "Digital" },
                    { value: "LiDAR", label: "LiDAR" },
                    { value: "Scan", label: "Scan" },
                    { value: "Plot", label: "Plot" },
                    { value: "Survey", label: "Survey" },
                    { value: "Other", label: "Other" },
                  ]}
                />
              </div>
              <div className="col-md-4">
                <label>Is Client Doing Survey? *</label>
                <Select
                  required
                  disabled={true}
                  name="client_survey"
                  value={this.state.client_survey}
                  onChange={this.handleSelectChange("client_survey")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                    { value: "Cooper Aerial", label: "Cooper Aerial" },
                  ]}
                />
              </div>
            </div>
            <p />
            <h5>Aerial Targets</h5>
            <hr />
            <button
              type="button"
              onClick={this.toggleHidden.bind(this)}
              title="Click to expand/hide aerial targets form"
              className="btn btn-secondary btn-sm"
            >
              {this.state.isHidden ? (
                <span>
                  Show Aerial Targets Form{" "}
                  <i className="fa fa-plus-circle" aria-hidden="true"></i>
                </span>
              ) : (
                <span>
                  Hide Aerial Targets Form{" "}
                  <i className="fa fa-minus-circle" aria-hidden="true"></i>
                </span>
              )}
            </button>
            <p />
            {!this.state.isHidden && (
              <div>
                <div className="row">
                  <div className="col">
                    <label>ABGPS *</label>
                    <Select
                      required
                      name="abgps"
                      value={this.state.abgps}
                      onChange={this.handleSelectChange("abgps")}
                      options={[
                        { value: "No", label: "No" },
                        { value: "Yes", label: "Yes" },
                      ]}
                    />
                  </div>
                  <div className="col">
                    <label>Panels *</label>
                    <input
                      type="text"
                      name="panels"
                      required
                      value={this.state.panels}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col">
                    <label>Cost Per Panel *</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        name="survey_quote"
                        required
                        value={this.state.survey_quote}
                        onChange={this.handleChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Panel Cost *</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        name="survey_cost"
                        required
                        value={surveyCost}
                        onChange={this.handleChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <p />
            <h5>Survey Services</h5>
            <hr />
            <button
              type="button"
              onClick={this.toggleHiddenServices.bind(this)}
              title="Click to expand/hide survey services form"
              className="btn btn-secondary btn-sm"
            >
              {this.state.isHiddenServices ? (
                <span>
                  Show Survey Services Form{" "}
                  <i className="fa fa-plus-circle" aria-hidden="true"></i>
                </span>
              ) : (
                <span>
                  Hide Survey Services Form{" "}
                  <i className="fa fa-minus-circle" aria-hidden="true"></i>
                </span>
              )}
            </button>
            <p />
            {!this.state.isHiddenServices && (
              <div>
                <div className="row">
                  <div className="col">
                    <label>Registered Land Surveyor *</label>
                  </div>
                  <div className="col-md-3">
                    <label>RLS Hrs *</label>
                    <input
                      type="text"
                      name="rls_hrs"
                      required
                      value={this.state.rls_hrs}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>RLS CP *</label>
                    <input
                      type="text"
                      name="rls_cp"
                      required
                      value={this.state.rls_cp}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>RLS Total *</label>
                    <input
                      type="text"
                      name="rlsTotal"
                      required
                      value={rlsTotal}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Survey Party Chief *</label>
                  </div>
                  <div className="col">
                    <label>SPC Hrs *</label>
                    <input
                      type="text"
                      name="spc_hrs"
                      required
                      value={this.state.spc_hrs}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>SPC CP *</label>
                    <input
                      type="text"
                      name="spc_cp"
                      required
                      value={this.state.spc_cp}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>SPC Total *</label>
                    <input
                      type="text"
                      name="spcTotal"
                      required
                      value={spcTotal}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Survey Technician *</label>
                  </div>
                  <div className="col-md-3">
                    <label>ST Hrs *</label>
                    <input
                      type="text"
                      name="st_hrs"
                      required
                      value={this.state.st_hrs}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>ST CP *</label>
                    <input
                      type="text"
                      name="st_cp"
                      required
                      value={this.state.st_cp}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>ST Total *</label>
                    <input
                      type="text"
                      name="stTotal"
                      required
                      value={stTotal}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>2 Person Survey Crew *</label>
                  </div>
                  <div className="col">
                    <label>Hrs *</label>
                    <input
                      type="text"
                      name="sc2_hrs"
                      required
                      value={this.state.sc2_hrs}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>CP *</label>
                    <input
                      type="text"
                      name="sc2_cp"
                      required
                      value={this.state.sc2_cp}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Total *</label>
                    <input
                      type="text"
                      name="sc2Total"
                      required
                      value={sc2Total}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>3 Person Survey Crew *</label>
                  </div>
                  <div className="col">
                    <label>Hrs *</label>
                    <input
                      type="text"
                      name="sc3_hrs"
                      required
                      value={this.state.sc3_hrs}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>CP *</label>
                    <input
                      type="text"
                      name="sc3_cp"
                      required
                      value={this.state.sc3_cp}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Total *</label>
                    <input
                      type="text"
                      name="sc3Total"
                      required
                      value={sc3Total}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>R/W Plans Tech *</label>
                  </div>
                  <div className="col">
                    <label>Hrs *</label>
                    <input
                      type="text"
                      name="rw_plans_hrs"
                      required
                      value={this.state.rw_plans_hrs}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>CP *</label>
                    <input
                      type="text"
                      name="rw_plans_cp"
                      required
                      value={this.state.rw_plans_cp}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Total *</label>
                    <input
                      type="text"
                      name="rwTotal"
                      required
                      value={rwTotal}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Survey Services Total *</label>
                    <input
                      type="text"
                      name="servicesTotal"
                      required
                      value={servicesTotal}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            )}
            <p />
            <div className="row">
              <div className="col">
                <label>Survey Notes (internal)</label>
                <textarea
                  name="survey_notes"
                  value={this.state.survey_notes}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Total Adjustment</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="total_adjustment"
                    required
                    value={this.state.total_adjustment}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Explanation</label>
                <input
                  type="text"
                  name="adjustment_reason"
                  value={this.state.adjustment_reason}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-2">
                <label>Bonus Percentage</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">%</span>
                  <input
                    type="number"
                    step="0.1"
                    min="0"
                    max="2.5"
                    name="bonus_perc"
                    required
                    value={this.state.bonus_perc || 0}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <h5>Total Quote Cost</h5>
            <hr />
            <div className="row">
              <div className="col">
                <label>Quote Cost</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="quote_cost"
                    required
                    value={this.state.quote_cost}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <input
              type="submit"
              value={this.state.editing ? "Update" : "Create"}
              className="btn btn-secondary btn-sm"
            />
          </form>
          <br />
        </div>
      </div>
    );
  }

}
