import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "hours",
    "rate",
    "total",
    "totalProductionCost",
    "panels",
    "panelCost",
    "panelTotal",
    "adminTotal",
    "projectTotal",
  ];

  connect() {
    this.updateTotals();
  }

  updateTotals() {
    let totalProductionCost = 0;

    this.hoursTargets.forEach((element, index) => {
      const hours = parseFloat(element.value) || 0;
      const rate = parseFloat(this.rateTargets[index].value) || 0;
      const total = hours * rate;

      this.totalTargets[index].value = total.toFixed(2);
      totalProductionCost += total;
    });

    this.totalProductionCostTarget.value = totalProductionCost.toFixed(2);

    const panels = parseFloat(this.panelsTarget.value) || 0;
    const panelCost = parseFloat(this.panelCostTarget.value) || 0;
    let panelTotal = panels * panelCost;
    panelTotal = Math.ceil(panelTotal); // Round up
    this.panelTotalTarget.value = panelTotal;

    // Calculate the initial project total without adminTotal
    const initialProjectTotal =
      totalProductionCost +
      panelTotal;

    // Calculate adminTotal as 2.5% of initialProjectTotal
    let adminTotal = initialProjectTotal * 0.025;
    adminTotal = Math.ceil(adminTotal); // Round up
    this.adminTotalTarget.value = adminTotal;

    // Calculate the final project total including adminTotal
    const finalProjectTotal = Math.ceil(initialProjectTotal + adminTotal); // Round up
    this.projectTotalTarget.value = finalProjectTotal;
  }
}
